import {CollectorElementType, ICollectorItem} from '../collector/collector.interface';
import {BomDataNode} from '../bom/bom-data-node';

export class CollectorItem extends BomDataNode implements ICollectorItem {
  readonly type = CollectorElementType.Item;

  response: string;
  responseEnabled: boolean;

  constructor(public id,
              public parentId,
              public title,
              public description,
              public color,
              public preDueDate,
              public dueDate,
              public startedAt,
              public completedAt,
              public createdAt,
              public updatedAt,
              public isOverdued: boolean = false,
              public priority = 'medium',
              public startsAt = null,
              public endsAt = null,
              public progress = 0,
              public effort = null,
              public order = null,
              public responsibleId,
              public lockedAt,
              public commentCount = null,
              public unreadCommentCount = null) {
    super(id, parentId, title, description, color, preDueDate, dueDate, startsAt, completedAt,
      createdAt, updatedAt, order, isOverdued, priority, startsAt, endsAt, progress, effort,
      responsibleId, lockedAt, commentCount, unreadCommentCount);
    this.categoryId = parentId;
  }
}
