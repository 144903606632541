<div class="dvtx-organization-global-settings" *ngIf="organizationProxy">
  <h3 class="mb-2">{{ 'ORGANIZATION.FOLDER_SETTINGS' | translate}}</h3>

  <div>
    <div class="w-100 d-flex align-items-center mb-2" style="max-width: 500px;">
      <div class="mr-3">
        <mat-slide-toggle [checked]="!!organizationProxy?.disable_private_dms"
                          (change)="togglePrivateDms($event)"></mat-slide-toggle>
      </div>

      <h4 class="mb-0">{{ 'ORGANIZATION.DISABLE_PRIVATE_DMS_FOR_WORKFLOWS' | translate}}</h4>
    </div>

    <!-- <div class="d-flex justify-content-start pl-2">
      <mat-checkbox [checked]="!!organizationProxy?.disable_private_dms"
                    (change)="togglePrivateDms($event)">
        {{ 'ORGANIZATION.DISABLE_PRIVATE_DMS_FOR_WORKFLOWS_BUTTON' | translate}}
      </mat-checkbox>
    </div>-->

    <div class="d-flex align-items-center mt-1 mb-2" style="max-width: 800px;">
      <div>
        <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200 mr-2">info</mat-icon>
      </div>
      <p [innerHTML]="'ORGANIZATION.DISABLE_PRIVATE_DMS_FOR_WORKFLOWS_INFO' | translate" class="mb-0"></p>
    </div>

    <hr>

    <!-- <h3 class="mb-1">{{ 'ORGANIZATION.GRANT_CLIENT_FOLDER_TO_MEMBERS' | translate}}</h3>-->

    <div class="w-100 d-flex align-items-center mb-2" style="max-width: 500px;">
      <div class="mr-3">
        <mat-slide-toggle [checked]="!!organizationProxy?.grant_clients_folder_to_members"
                          (change)="toggleGrantClientsFolderToMembers($event)"></mat-slide-toggle>
      </div>

      <h4 class="mb-0">{{ 'ORGANIZATION.GRANT_CLIENT_FOLDER_TO_MEMBERS_BUTTON' | translate}}</h4>
    </div>

    <!-- <div class="d-flex justify-content-start pl-2">
      <mat-checkbox [checked]="!!organizationProxy?.grant_clients_folder_to_members"
                    (change)="toggleGrantClientsFolderToMembers($event)">
        {{ 'ORGANIZATION.GRANT_CLIENT_FOLDER_TO_MEMBERS_BUTTON' | translate}}
      </mat-checkbox>
    </div>-->

    <div class="d-flex align-items-center mt-1 mb-2" style="max-width: 800px;">
      <div>
        <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200 mr-2">info</mat-icon>
      </div>
      <p [innerHTML]="'ORGANIZATION.GRANT_CLIENT_FOLDER_TO_MEMBERS_INFO' | translate" class="mb-0"></p>
    </div>

    <ng-container *ngIf="(featureSet$ | async)?.hasMyStorage">

      <hr>

      <div class="w-100 d-flex align-items-center mb-2" style="max-width: 800px;">
        <div class="mr-3">
          <mat-slide-toggle [checked]="!organizationProxy?.disable_business_partner_dms_contacts"
                            (change)="toggleDisableBusinessPartnerDmsContacts($event)"></mat-slide-toggle>
        </div>

        <h4 class="mb-0">{{ 'ORGANIZATION.DISABLE_BUSINESS_PARTNER_DMS_CONTACTS' | translate}}</h4>
      </div>

      <!-- <div class="d-flex justify-content-start pl-2">
        <mat-checkbox [checked]="!!organizationProxy?.grant_clients_folder_to_members"
                      (change)="toggleGrantClientsFolderToMembers($event)">
          {{ 'ORGANIZATION.GRANT_CLIENT_FOLDER_TO_MEMBERS_BUTTON' | translate}}
        </mat-checkbox>
      </div>-->

      <div class="d-flex align-items-center mt-1 mb-2" style="max-width: 800px;">
        <div>
          <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200 mr-2">info</mat-icon>
        </div>
        <p [innerHTML]="'ORGANIZATION.DISABLE_BUSINESS_PARTNER_DMS_CONTACTS_INFO' | translate" class="mb-0"></p>
      </div>

      <div>
        <div class="d-flex align-items-center">
          <div>{{ 'ORGANIZATION.MANUAL_DMS_CONTACT_CLEANUP' | translate }}</div>
          <div>
            <button class="ml-3" mat-raised-button [disabled]="cleanupStarted" (click)="startManualContactCleanup()">{{ 'ORGANIZATION.MANUAL_DMS_CONTACT_CLEANUP_ACTION' | translate }}</button>
          </div>
        </div>

        <div>
          <small>{{'ORGANIZATION.MANUAL_DMS_CONTACT_CLEANUP_AT' | translate}}:&nbsp;
            {{ organizationProxy?.contact_cleanup_at ? (organizationProxy?.contact_cleanup_at | date:'medium') : '-' }}</small>
        </div>

        <div class="d-flex align-items-center mt-1 mb-2" style="max-width: 800px;">
          <div>
            <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200 mr-2">info</mat-icon>
          </div>
          <p [innerHTML]="'ORGANIZATION.MANUAL_DMS_CONTACT_CLEANUP_INFO' | translate" class="mb-0"></p>
        </div>
      </div>
    </ng-container>
  </div>

  <hr>

  <h3 class="mb-2">{{ 'ORGANIZATION.EMAIL_SETTINGS' | translate}}</h3>

  <div>
    <div class="w-100 d-flex align-items-center mb-2" style="max-width: 500px;">
      <div class="mr-3">
        <mat-slide-toggle [checked]="!!organizationProxy?.show_client_in_email"
                          (change)="toggleShowClientInEmail($event)"></mat-slide-toggle>
      </div>

      <h4 class="mb-0">{{ 'ORGANIZATION.SHOW_CLIENT_IN_EMAIL_BUTTON' | translate}}</h4>
    </div>
  </div>

  <div class="d-flex align-items-center mt-1 mb-2" style="max-width: 800px;">
    <div>
      <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200 mr-2">info</mat-icon>
    </div>
    <p [innerHTML]="'ORGANIZATION.SHOW_CLIENT_IN_EMAIL_INFO' | translate" class="mb-0"></p>
  </div>

  <hr>

  <h3 class="mb-2">{{ 'GENERAL.SECURITY' | translate}}</h3>

  <!-- <h4 class="mb-1">{{ 'ORGANIZATION.ENFORCEMENT_OF_TWO_FACTOR_AUTHENTICATION' | translate}}</h4>-->

  <div class="w-100 d-flex align-items-center mb-2" style="max-width: 500px;">
    <div class="mr-3">
      <mat-slide-toggle [checked]="!!organizationProxy?.enforce_two_factor_auth"
                        (change)="toggleForceTwoFactorAuthentication($event)"></mat-slide-toggle>
    </div>

    <h4 class="mb-0">{{ 'ORGANIZATION.ENFORCE_TWO_FACTOR_AUTHENTICATION' | translate}}</h4>
  </div>

  <div class="d-flex align-items-center mt-1 mb-2" style="max-width: 800px;">
    <div>
      <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200 mr-2">info</mat-icon>
    </div>
    <p [innerHTML]="'ORGANIZATION.ENFORCEMENT_OF_TWO_FACTOR_AUTHENTICATION_INFO' | translate" class="mb-0"></p>
  </div>

  <!-- <div class="d-flex justify-content-start pl-2">
    <mat-checkbox [checked]="!!organizationProxy?.grant_clients_folder_to_members"
                  (change)="toggleGrantClientsFolderToMembers($event)">
      {{ 'ORGANIZATION.GRANT_CLIENT_FOLDER_TO_MEMBERS_BUTTON' | translate}}
    </mat-checkbox>
  </div>-->

  <!-- <h2 class="mb-2">{{ 'CLIENT.CLIENT' | translate}}</h2>

  <div class="pl-3">
    <h3 class="mb-1">{{ 'ORGANIZATION.SHOW_CLIENT_TO_EXTERNAL' | translate}}</h3>

    <div class="d-flex justify-content-start pl-2">
      <mat-checkbox [checked]="!!organizationProxy?.show_client_to_business_partner"
                    (change)="toggleShowClientToBusinessPartner($event)">
        {{ 'ORGANIZATION.SHOW_CLIENT_TO_EXTERNAL_BUTTON' | translate}}
      </mat-checkbox>
    </div>

    <p class="mt-2 dvtx-organization-settings__explanation">
      {{ 'ORGANIZATION.SHOW_CLIENT_TO_EXTERNAL_INFO' | translate}}
    </p>

    <hr>

    <h3 class="mb-1">{{ 'ORGANIZATION.SHOW_CLIENT_IN_EMAIL' | translate}}</h3>

    <div class="d-flex justify-content-start pl-2">
      <mat-checkbox [checked]="!!organizationProxy?.show_client_in_email"
                    (change)="toggleShowClientInEmail($event)">
        {{ 'ORGANIZATION.SHOW_CLIENT_IN_EMAIL_BUTTON' | translate}}
      </mat-checkbox>
    </div>
    <p class="mt-2 dvtx-organization-settings__explanation mb-2">
      {{ 'ORGANIZATION.SHOW_CLIENT_IN_EMAIL_INFO' | translate}}
    </p>
  </div>-->
</div>
