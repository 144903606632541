import {Component, EventEmitter, forwardRef, Injector, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, combineLatest, Subject} from 'rxjs';
import {ContactListDto, contactListDtoType} from 'app/models/contact-list-dto.model';
import {filter, map, startWith, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {ContactSelectors} from 'app/+store/contact';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {LoadAll} from 'app/+store/contact/contact.actions';
import {OrganizationSelectors} from 'app/+store/organization';
import {DvtxControlValueAccessor} from 'app/shared/modules/base-form-elements/components/1_control-value-accessor-components/DvtxControlValueAccessor';
import {AngularTokenService} from 'angular-token';
import { ConfirmationDialogParticipantComponent } from 'app/shared/components/dialogs/confirmation-dialog-participant/confirmation-dialog-participant.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'dvtx-recipient-autocomplete',
  templateUrl: './recipient-autocomplete.component.html',
  styleUrls: ['./recipient-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecipientAutocompleteComponent),
      multi: true,
    }
  ]
})
export class RecipientAutocompleteComponent extends DvtxControlValueAccessor implements OnInit, OnChanges, OnDestroy {
  onDestroy = new Subject();

  myControl = new UntypedFormControl();
  filteredOptions: Observable<ContactListDto[]>;

  @Input()
  filterType?: contactListDtoType;

  @Input()
  valueIsEmail: boolean = false;

  @Input() showEmail = false;

  @Input()
  excludedIds: string[] = [];

  @Input() placeholder = 'GENERAL.EMAIL_OR_NAME';

  @Input()
  clientContact: boolean = false;
  @Input() loadNew: boolean = true;

  @Input() set value(val: string) {
    this.writeValue(val);
  }

  @Output()
  onSelect = new EventEmitter();

  constructor(
    protected injector: Injector,
    private store: Store<AppState>,
    public dialog: MatDialog,
    private _tokenSvc: AngularTokenService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.excludedIds && this.excludedIds.length > 0) {
      this.excludedIds = this.excludedIds.map(a => a.toLocaleLowerCase());
    } else {
      this.excludedIds = [];
    }
    this.store.select(OrganizationSelectors.getSelected).pipe(
      filter(x => !!x),
      takeUntil(this.onDestroy)
    ).subscribe((organization) => {
      if (this.loadNew)
      this.store.dispatch(new LoadAll(organization))
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnChanges(changes) {
    // if (changes.excludedIds) {
    //   this.filteredOptions =
    //     combineLatest(
    //       this.myControl.valueChanges.pipe(startWith('')),
    //       this.store.select(ContactSelectors.getFilteredPersonContactsOfSelectedOrg(undefined, this.excludedIds)),
    //     ).pipe(
    //       map(([value, contacts]: [string, ContactListDto[]]) => {
    //         const filteredContacts = this._filter(value, contacts);
    //         if (this.clientContact) {
    //           return filteredContacts.filter(contact => (this.excludedIds.indexOf(contact.email.toLocaleLowerCase()) === -1)
    //         );
    //         } else {
    //           return filteredContacts.filter(contact => (
    //             contact.email !== this._tokenSvc.currentAuthData.uid && this.excludedIds.indexOf(contact.email.toLocaleLowerCase()) === -1
    //           )
    //         );
    //         }
    //       })
    //     );
    // }
  }

  private _filter(value: string, contacts: ContactListDto[]): ContactListDto[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return contacts.filter((entry: ContactListDto) => {
        return entry.name.toLowerCase().indexOf(filterValue) >= 0
          || entry.email.toLowerCase().indexOf(filterValue) >= 0
          || entry.firstName && entry.firstName.toLowerCase().indexOf(filterValue) >= 0
          || entry.lastName && entry.lastName.toLowerCase().indexOf(filterValue) >= 0
      });
    } else {
      return [];
    }
  }

  writeValue(obj: any): void {
    this.myControl.setValue(obj);
  }

  openConfirmationSelectPerson($event: MatAutocompleteSelectedEvent) {
    if (!$event.option.value || !$event.option.value.email) return;
    if (this.clientContact) {
      this.contactSelected($event, false, false);
      return;
    }
    this.dialog.open(ConfirmationDialogParticipantComponent, {
      disableClose: true,
      data: {
        title: 'GENERAL.ADD_PARTICIPANT',
        message: 'GENERAL.ADD_PARTICIPANT_CONFIRMATION',
        submitButtonTitle: 'GENERAL.CONFIRM_ACTION',
        cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
        person: $event.option.value,
        recursiveOption: true,
        adminOption: true,
        onSubmitAction: (recursive, admin) => {
          this.contactSelected($event, recursive, admin);
        },
        onCancelAction: () => {
          this.writeValue(null);
        }
      }
    });
}

  contactSelected($event: MatAutocompleteSelectedEvent, recursive, admin) {
    if (this.valueIsEmail) {
      this.notifyOnChange($event.option.value.email);
      this.onSelect.emit({ email: $event.option.value.email, admin: admin, recursive: recursive});
    } else {
      this.notifyOnChange($event.option.value);
      this.onSelect.emit({ email: $event.option.value, admin: admin, recursive: recursive});
    }
    this.writeValue(null);
  }

  // Used to display the text in the input field.
  displayFn(contact: ContactListDto): string {
    return contact ? contact.name : '';
  }

  onKeyEnter() {
    if (typeof this.myControl.value !== 'string' && !this.valueIsEmail) {
      this.onSelect.emit(this.myControl.value);
    } else if (this.valueIsEmail) {
      const value = (this.myControl.value && this.myControl.value.email) ? this.myControl.value.email : this.myControl.value;
      this.onSelect.emit(value);
    }
    this.writeValue(null);
  }

  onBlurEventHandler() {
    // use time out to get selected value before emptying the variable
    setTimeout(() => {
        this.filteredOptions = null;
    }, 200);
    if (typeof this.myControl.value !== 'string' && !this.valueIsEmail) {
      this.notifyOnChange(this.myControl.value);
    } else if (this.valueIsEmail) {
      const value = (this.myControl.value && this.myControl.value.email) ? this.myControl.value.email : this.myControl.value;
      this.notifyOnChange(value);
    }
  }
  onFocusEventHandler() {
    setTimeout(() => {
      this.filteredOptions =
      combineLatest(
        this.myControl.valueChanges.pipe(startWith('')),
        this.store.select(ContactSelectors.getFilteredPersonContactsOfSelectedOrg(undefined, this.excludedIds)),
      ).pipe(
        map(([value, contacts]: [string, ContactListDto[]]) => {
          const filteredContacts = this._filter(value, contacts);
          if (this.clientContact) {
            return filteredContacts.filter(contact => (this.excludedIds.indexOf(contact.email.toLocaleLowerCase()) === -1)
          );
          } else {
            return filteredContacts.filter(contact => (
              contact.email !== this._tokenSvc.currentAuthData.uid && this.excludedIds.indexOf(contact.email.toLocaleLowerCase()) === -1
            )
          );
          }
        })
      );
    }, 300);
  }
}
