import {QuickCollectorActions, QuickCollectorActionTypes, UpsertDataNodesSuccess} from './quickcollector.actions';
import {adapter, initialState, State} from './quickcollector.state';

export function reducer(state = initialState, action: QuickCollectorActions): State {
  const current = state.selected;
  switch (action.type) {
    /*
      LOAD ONE REDUCERS
    */
    case QuickCollectorActionTypes.LoadOne:
      return {
        ...state,
        selected: null,
        loading: action.loading
      };
    case QuickCollectorActionTypes.LoadOneSuccess:
      return adapter.upsertOne(action.quickcollector, {
        ...state,
        selected: action.quickcollector,
        loading: false,
      });
    case QuickCollectorActionTypes.LoadOneFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };

    /*
      LOAD ALL REDUCERS
    */
    case QuickCollectorActionTypes.LoadAll:
      return {
        ...state,
        selected: null,
        loading: true
      };
    case QuickCollectorActionTypes.LoadAllSuccess:
      return adapter.upsertMany(action.quickcollectors, {
        ...state,
        selected: current ? state.entities[current.id] : null,
        loading: false,
      });
    case QuickCollectorActionTypes.LoadAllFail:
      return {
        ...state,
        selected: null,
        loading: false,
      };

    /*
      CREATE REDUCERS
    */
    case QuickCollectorActionTypes.Create:
      return {
        ...state,
        selected: null,
        creating: true
      };
    case QuickCollectorActionTypes.CreateSuccess:
      return adapter.upsertOne(action.quickcollector, {
        ...state,
        selected: action.quickcollector,
        creating: false,
      });
    case QuickCollectorActionTypes.CreateFail:
      return {
        ...state,
        selected: null,
        creating: false,
      };

    /*
      SAVE DRAFT REDUCERS
    */
    case QuickCollectorActionTypes.SaveDraft:
      return {
        ...state,
        updating: true
      };
    case QuickCollectorActionTypes.SaveDraftSuccess:
      return adapter.upsertOne(action.quickcollector, {
        ...state,
        selected: action.quickcollector,
        updating: false,
      });
    case QuickCollectorActionTypes.SaveDraftFail:
      return {
        ...state,
        selected: null,
        updating: false,
      };

    /*
      PUBLISH REDUCERS
    */
    case QuickCollectorActionTypes.Publish:
      return {
        ...state,
        updating: true
      };
    case QuickCollectorActionTypes.PublishSuccess:
      return adapter.upsertOne(action.quickcollector, {
        ...state,
        selected: action.quickcollector,
        updating: false,
      });
    case QuickCollectorActionTypes.PublishFail:
      return {
        ...state,
        updating: false,
      };

    /*
      UPDATE REDUCERS
    */
    case QuickCollectorActionTypes.UpdateRecipients:
      return {
        ...state,
        updating: true
      };
    case QuickCollectorActionTypes.UpdateRecipientsSuccess:
      return adapter.upsertOne(action.quickcollector, {
        ...state,
        selected: action.quickcollector,
        updating: false,
      });
    case QuickCollectorActionTypes.UpdateRecipientsFail:
      return {
        ...state,
        selected: null,
        updating: false,
      };


    /*
      ORDER ITEMS
    */
    case QuickCollectorActionTypes.OrderItems:
      return {
        ...state,
        updating: true
      };
    case QuickCollectorActionTypes.OrderItemsSuccess:
      return {
        ...state,
        updating: false
      };
    case QuickCollectorActionTypes.OrderItemsFail:
      return {
        ...state,
        updating: false
      };
    case QuickCollectorActionTypes.Duplicate:
      return {
        ...state,
        updating: true
      };
    case QuickCollectorActionTypes.DuplicateSuccess:
      return adapter.upsertOne(action.quickcollector, {
        ...state,
        selected: action.quickcollector,
        updating: false,
      });
    case QuickCollectorActionTypes.DuplicateFail:
      return {
        ...state,
        updating: false,
      };
  }
  return state;
}
