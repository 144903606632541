<ng-container [ngSwitch]="action">
  <!-- Button to open the dialog -->
  <ng-container *ngSwitchCase="ParticipantActionType.ParticipantRole">
    <button mat-icon-button color="gray"
            (click)="openParticipantRoleDialog()"
            [matTooltip]="'PROJECT_ROOM.ADD_NEW_MEMBERS' | translate"
            [disableRipple]="true"><mat-icon >person_add</mat-icon></button>
  </ng-container>

  <!-- Menu item Button to open the dialog -->
  <ng-container *ngSwitchCase="ParticipantActionType.ParticipantRoleMenuItem">
    <button mat-menu-item class="dvtx-menu-item"
            color="gray" (click)="openParticipantRoleDialog()" [matTooltip]="'PROJECT_ROOM.ADD_NEW_MEMBERS' | translate">
      <mat-icon mataffix>person_add</mat-icon>
      {{'PROJECT_ROOM.ADD_MEMBER' | translate}}
    </button>
  </ng-container>

  <!-- Bar Button to open the dialog -->
  <ng-container *ngSwitchCase="ParticipantActionType.ParticipantRoleBarButton">
    <button mat-button color="gray" (click)="openParticipantRoleDialog()" [matTooltip]="'PROJECT_ROOM.ADD_NEW_MEMBERS' | translate">
      <mat-icon mataffix>person_add</mat-icon>
      {{'PROJECT_ROOM.ADD_MEMBER' | translate}}
    </button>
  </ng-container>


  <!-- Embeddable view to be used inside the settings dialog -->
  <ng-container *ngSwitchCase="ParticipantActionType.ParticipantRoleStandAlone">
    <ng-template [ngIf]="contextPortal">
      <div class="d-flex flex-column" style="width: 98%">
        <div>
          <ng-template [cdkPortalOutlet]="contextPortal"></ng-template>
        </div>

        <div class="d-flex flex-row justify-content-end" *ngIf="showSaveButtons">
          <button mat-raised-button color="primary"
                  (click)="save()" [disabled]="this.form.pristine || this.form.invalid">
            {{ 'GENERAL.APPLY_CHANGE_ACTION' | translate }}
          </button>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>

<ng-template cdkPortal #context>
  <h3 *ngIf="showTitle">{{ 'WORKFLOW_ENGINE.ADD_MEMBER_TO_PROJECT_ROOM' | translate }}</h3>

  <div class="row mb-2">
    <ng-container *ngIf="organization">
      <div class="col-12 d-flex flex-row align-items-center">
        <div style="padding-right: 15px;">
          <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-300">person_add</mat-icon>
        </div>

        <dvtx-recipient-autocomplete
          style="width: 100%"
          elementName="E-Mail"
          [formControl]="newParticipant"
          [showEmail]="showEmail"
          [placeholder]="'CONTACTS.ADDRESS_BOOK_SEARCH'"
          (onSelect)="addParticipant($event)"
          [excludedIds]="excludedIds"
          [valueIsEmail]="true"
          [loadNew]="false">
        </dvtx-recipient-autocomplete>

        <div class="ml-2">
          <dvtx-add-and-invite (onSelect)="inviteAndAddParticipant($event)"
                               [action]="AddOrInviteActionType.Button"
                               [processId]="_processId"></dvtx-add-and-invite>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="dvtx-participant-role-dialog">
    <ul>
      <li>
        <div class="row m-0">
          <div class="col-5">
            {{ 'WORKFLOW_ENGINE.MEMBER' | translate }}
          </div>
          <div class="col-2 text-center">
            <div class="d-flex justify-content-center">
              <div class="align-self-center">
                {{ 'ROLE.PR_ADMIN' | translate }}
              </div>
              <div class="align-self-center">
                <button mat-icon-button
                        color="gray"
                        matTooltip="{{ 'PROJECT_ROOM.PROJECT_ROOM_ADMIN_INFO' | translate }}">
                  <mat-icon>info</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-4 text-center">
            <div class="d-flex justify-content-center">
              <div class="align-self-center">
                {{ 'WORKFLOW_ENGINE.APPLY_TO_SUBWORKFLOWS' | translate }}
              </div>
              <div class="align-self-center">
                <button mat-icon-button
                        color="gray"
                        matTooltip="{{ 'PROJECT_ROOM.APPLY_TO_SUBWORKFLOWS' | translate }}">
                  <mat-icon>info</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-1 text-center">
            <div class="d-flex justify-content-center">
              <div class="align-self-center">
                {{ 'GENERAL.REMOVE_ACTION' | translate }}
              </div>
              <div class="align-self-center">
                <button mat-icon-button
                        color="gray"
                        matTooltip="{{ 'PROJECT_ROOM.REMOVE_PARTICIPANT' | translate }}">
                  <mat-icon>info</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <!-- Participant Listing -->
    <ul style="min-height: 300px; max-height: 400px; overflow-y: auto">
      <!-- Participant Listing -  Current User (first entry) -->
      <li *ngIf="currentParticipant" class="m-0 p-1">
        <div class="row m-0">
          <div class="col-5 dvtx-contact-chip-cell text-truncate">
            <dvtx-avatar [size]="'sm'" [profile]="avatarService.getProfile(currentParticipant.email, {email: currentParticipant?.email, name: currentParticipant?.name})" [showEmail]="showEmail" [showTitle]="true"></dvtx-avatar>
          </div>
          <div class="col-2 text-center align-self-center">
            <mat-slide-toggle [checked]="currentParticipant.isAdmin" [disabled]="true" color="primary">{{ 'ROLE.MODERATOR' | translate }}</mat-slide-toggle>
          </div>
          <div class="col-4 text-center align-self-center"></div>
          <div class="col-1 text-center align-self-center"></div>
        </div>
      </li>

      <!-- Participant Listing -  Remaining Users (remaining entries without current participant) -->
      <li class="m-0 p-1" *ngFor="let participant of remainingParticipants">
        <div class="row m-0">
          <div class="col-5 dvtx-contact-chip-cell text-truncate">
            <dvtx-avatar [size]="'sm'" [profile]="avatarService.getProfile(participant?.email, {email: participant?.email, name: participant?.name})" [showEmail]="showEmail" [showTitle]="true"></dvtx-avatar>
          </div>
          <!-- Moderator column -->
          <div class="col-2 text-center align-self-center">
            <mat-slide-toggle *ngIf="!currentParticipant?.isAdmin || participant?.owner"
                              [checked]="participant?.isAdmin"
                              [disabled]="!currentParticipant?.isAdmin || participant?.owner">{{ 'ROLE.MODERATOR' | translate }}</mat-slide-toggle>

            <mat-slide-toggle *ngIf="!(!currentParticipant?.isAdmin || participant?.owner)"
                              [checked]="participant?.isAdmin"
                              (change)="openModeratorConfirmationDialog($event, participant)"
                              color="primary"
                              [disabled]="!currentParticipant?.isAdmin || participant?.owner">{{ 'ROLE.MODERATOR' | translate }}</mat-slide-toggle>
          </div>

          <!-- Recursive application column -->
          <div class="col-4 text-center align-self-center">
            <ng-container *ngIf="currentParticipant?.isAdmin">
              <mat-slide-toggle *ngIf="(participant?.owner)" color="primary"
                                [checked]="participant?.recursive"
                                [disabled]="participant?.owner"></mat-slide-toggle>
              <mat-slide-toggle *ngIf="!(participant?.owner)"
                                [checked]="participant?.recursive"
                                (change)="applyToSubWorkflowsConfirmationDialog($event, participant)"
                                color="primary"
                                [disabled]="participant?.owner"></mat-slide-toggle>
            </ng-container>
          </div>
          <div class="col-1 text-center align-self-center">
            <ng-container *ngIf="participant && !participant?.owner && currentParticipant?.isAdmin">
              <button (click)="openRemoveParticipantConfirmDialog(participant)" mat-icon-button color="gray">
                <mat-icon mataffix>close</mat-icon>
              </button>
            </ng-container>
          </div>
        </div>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template cdkPortal #buttonsToolbar>
  <div class="d-flex justify-content-between w-100" *ngIf="showSaveButtons">
    <div>
      <button mat-button color="primary" (click)="goToPermissionView()" *ngIf="currentParticipant?.isAdmin || currentParticipant?.owner">
        {{ 'AUTH.PERMISSIONS' | translate }}
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
    <div>
      <button mat-raised-button color="primary" (click)="closeDialog()">{{ 'GENERAL.CLOSE_ACTION' | translate }}</button>
    </div>
  </div>
</ng-template>
