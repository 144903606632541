<div class="mat-dialog-inner-wrap" *ngIf="!!orgaContact">
  <!-- <div class="mat-dialog-header">
    <h2 mat-dialog-title>{{ 'CONTACTS.EDIT_ORG_TITLE' | translate }}</h2>
    <button class="mat-dialog-close" mat-mini-fab color="warn" mat-dialog-close>
      <mat-icon aria-label="close dialog">clear</mat-icon>
    </button>
  </div> -->

  <div>

    <div *ngIf="(administrationRights | async).hasAdministrationRights === false && (currentUser | async).email !== createdBy">
      <dvtx-alert [color]="'info'" [text]="'ADDRESSBOOK.NEED_ADMIN_PRIVILEGES'"></dvtx-alert>
    </div>
    <mat-accordion displayMode="flat" multi>
      <mat-expansion-panel hideToggle expanded [disabled]="true" class="mat-elevation-z0 px-3 px-lg-5">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'CONTACTS.SET_VISIBILITY_FOR_INFORMATION' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <dvtx-contact-visibility-chooser
        [(ngModel)]="orgaContact.contactVisibility"
        [isDisabled]="!editmode"
        ></dvtx-contact-visibility-chooser>
      </mat-expansion-panel>
      <mat-expansion-panel expanded class="mat-elevation-z0 px-3 px-lg-5">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'CONTACTS.GENERAL_DATA' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <dvtx-create-organization-dialog
          [isDisabled]="!editmode"
          [(ngModel)]="orgaContactForm"
          [displayFurtherDataFields]="true"
          title="" >
            <dvtx-api-select-cva
              class="type-or-location"
              listingPath="name"
              listingType="service-types"
              [(ngModel)]="orgaTypeId"
              [elementName]="'ORG_CONTACT.PARENT_TYPE' | translate"
              [isDisabled]="!editmode" ></dvtx-api-select-cva>
        </dvtx-create-organization-dialog>
      </mat-expansion-panel>
      <mat-expansion-panel expanded class="mat-elevation-z0 px-3 px-lg-5">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'CONTACTS.ASSIGNED-MEMBER' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="icon-content-layout">
          <mat-icon *ngIf="editmode">account_box</mat-icon>
          <div class="icon-content-layout--content" *ngIf="editmode">
            <div class="row">
              <div class="col-12 col-md-7">
                <dvtx-contact-select
                  elementName="{{'CONTACTS.NAME_OR_EMAIL' | translate}}"
                  [(ngModel)]="contactAssignment"
                  [filterType]="filterType.naturalPersonContact"
                  [excludedIds]="(orgaContactEmployeesIds | async)" ></dvtx-contact-select>
              </div>
              <div class="col-12 col-md-3 align-self-center">
                <button *ngIf="!isDisabled" [disabled]="!contactAssignment" (click)="assignContact()" mat-flat-button color="primary">
                  {{ 'GENERAL.ADD_ACTION' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>


        <mat-table [dataSource]="orgaContactEmployees" class="assigned-to-contacts-table">
          <ng-container matColumnDef="name">
            <mat-cell *matCellDef="let member" class="assigned-to-contacts-table-contact-name">
              <dvtx-avatar [size]="'xs'" [profile]="avatarService.getProfile(member.email)" [showTitle]="false"></dvtx-avatar>
              {{member?.name}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="email">
            <mat-cell *matCellDef="let member" class="assigned-to-contacts-table-contact-email">
              <mat-icon class="assigned-to-contacts-table-icon">email</mat-icon>
              {{member?.email ? member?.email : '-'}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="phone">
            <mat-cell *matCellDef="let member" class="assigned-to-contacts-table-contact-phone">
              <mat-icon class="assigned-to-contacts-table-icon">phone</mat-icon>
              {{member?.telephone ? member.telephone : '-'}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-cell *matCellDef="let member" class="assigned-to-contacts-table-actions">
              <button
              mat-icon-button color="gray" type="button"
              [disabled]="disabled" class="add-button"
              [disableRipple]="true"
              *ngIf="!member?.hasAccount && !(hasPendingInvitations(member?.email) | async) && editmode"
              (click)="inviteBusinessPartner(member?.email)"
              matTooltip="{{'INVITATIONS.INVITE'| translate}}">
                <mat-icon>add_circle_outline</mat-icon>
              </button>
              <button
                *ngIf="editmode"
                mat-icon-button
                (click)="removeContactAssignment(member?.id)"
                matTooltip="{{'ORGANIZATION.DEASSIGN_CONTACT'| translate}}">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <mat-row *matRowDef="let rowData; columns: ['name', 'email', 'phone', 'actions']"></mat-row>
        </mat-table>
      </mat-expansion-panel>

      <mat-expansion-panel expanded class="mat-elevation-z0 px-3 px-lg-5">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'ORGANIZATION.PARENT_COMPANY' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="icon-content-layout">
          <mat-icon *ngIf="editmode">domain</mat-icon>
          <div class="icon-content-layout--content" *ngIf="editmode">
            <div class="row">
              <div class="col-12 col-md-7">
                <dvtx-contact-select
                  elementName="{{'CONTACTS.NAME_OR_EMAIL' | translate}}"
                  [filterType]="filterType.organizationContact"
                  [(ngModel)]="parentCompanyAssignment"
                  [excludedIds]="[orgaContact?.id]"
                  ></dvtx-contact-select>
              </div>
              <div class="col-12 col-md-3 align-self-center">
                <button *ngIf="!isDisabled" [disabled]="!parentCompanyAssignment" (click)="saveParentOrganization()" mat-flat-button color="primary">
                  {{ 'CONTACTS.ASSIGN-TO-CONTACT' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <mat-list *ngIf="(currentParentOrg | async) as pc" role="list">
          <mat-list-item role="listitem" class="px-0">
            <div class="current-parent">
              <div>{{pc?.name}}</div>
              <button *ngIf="editmode"
                mat-icon-button (click)="removeParentOrganization(pc?.id)"
                [matTooltip]="'ORG_CONTACT.REMOVE_PARENT_ORG' | translate">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </div>
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div mat-dialog-actions style="position: absolute;bottom: 0%;" class="col-12 d-flex justify-content-end" *ngIf="(administrationRights | async).hasAdministrationRights || (currentUser | async).email === createdBy">
    <button *ngIf="!editmode" (click)="editmode = true;" mat-raised-button color="primary">
      <mat-icon matAffix>edit</mat-icon>
      {{ 'GENERAL.EDIT_ACTION' | translate }}
    </button>
    <button *ngIf="editmode" [disabled]="!orgaContactForm.isValid"
      mat-raised-button (click)="saveData()" color="primary">
      <mat-icon matAffix>save</mat-icon>
      {{ 'GENERAL.SAVE_ACTION' | translate }}
    </button>
    <button mat-dialog-close mat-button >{{'GENERAL.CLOSE_ACTION' | translate}}</button>
  </div>
</div>
