<div class="dvtx-document-preview-details-container pb-3 px-3 p-2">
  <div class="pt-2 five-f-details-content-container">
    <!-- <h2 class="mb-0">{{ 'PREVIEW_BROWSER.DETAILS' | translate }}</h2> -->

    <div class="mt-2 five-f-details-content-container__list-item__property-container">
      <div class="w-100 five-f-details-content-container__list-item__property">
        <h4 class="font-weight-bold">{{ 'DMS.FILENAME' | translate }}</h4>
        <div class="mt-1">
          <div class="pr-2"> <!-- pr-2 shifts the edit icon to the left on long filenames to be aligned with three dots. -->
            <dvtx-title-editable
              [classNames]="'m-0'"
              [renameDoc]="true"
              (onChange)="editArtifactName(_artifact, $event)"
              [fontSize]="'null'"
              [editIcon]="true"
              [disabled]="!policy?.canRenameArtifact"
              [value]="_artifact?.title">
            </dvtx-title-editable>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 five-f-details-content-container__list-item__property-container">
      <div class="five-f-details-content-container__list-item__property five-f-details-content-container__list-item__property__col-3">
        <h4 class="font-weight-bold">{{ 'DMS.UPLOADED_BY' | translate }}</h4>
        <div class="mt-1 d-flex align-items-center" matTooltip="{{ _artifact?.uploaderEmail }}">
          <dvtx-avatar [size]="'xs'" [profile]="avatarService.getProfile(_artifact?.uploaderEmail)">
          </dvtx-avatar>
          <div class="text-truncate">
            {{ _artifact?.uploaderName }}
          </div>
        </div>
      </div>

      <div class="five-f-details-content-container__list-item__property five-f-details-content-container__list-item__property__col-3">
        <h4 class="font-weight-bold">{{ 'GENERAL.UPLOADED_AT' | translate }}</h4>
        <div class="mt-1 d-flex align-items-center">
          {{ _artifact?.createdAt | date: 'shortDate' }}
        </div>
      </div>
    </div>

    <ng-template [ngIf]="policy?.canUpdateArtifact">
      <div class="mt-2 mb-2 five-f-details-content-container__list-item__property-container">
        <div class="five-f-details-content-container__list-item__property five-f-details-content-container__list-item__property__col-3 five-f-mat-input pr-2">
          <h4 class="font-weight-bold">{{ 'COLLECTOR.SAMPLE_UPLOAD' | translate }}</h4>
          <mat-form-field>
            <mat-select [formControl]="roleFormCtrl"
                        (selectionChange)="updateAttribute(_artifact?.id, 'role', $event.value)" >
              <mat-option value="default">{{'Upload' | translate}}</mat-option>
              <mat-option value="template">{{'COLLECTOR.SAMPLE_DOCUMENT' | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="five-f-details-content-container__list-item__property five-f-details-content-container__list-item__property__col-3 five-f-mat-input">
          <h4 class="font-weight-bold">{{ 'COLLECTOR.DOCUMENT_TYPE' | translate }}</h4>
          <mat-form-field>
            <mat-select [formControl]="documentTypeFormCtrl"
                        (selectionChange)="updateAttribute(_artifact?.id, 'document_type', $event.value)" >
              <mat-option [value]="DocumentType.Document">{{DocumentTypeTranslations.Document | translate }}</mat-option>
              <mat-option [value]="DocumentType.Invoice">{{DocumentTypeTranslations.Invoice | translate }}</mat-option>
              <mat-option [value]="DocumentType.IngoingInvoice">{{DocumentTypeTranslations.IngoingInvoice | translate }}
              </mat-option>
              <mat-option [value]="DocumentType.OutgoingInvoice">{{DocumentTypeTranslations.OutgoingInvoice | translate }}
              </mat-option>
              <mat-option [value]="DocumentType.Receipt">{{DocumentTypeTranslations.Receipt | translate }}</mat-option>
              <mat-option [value]="DocumentType.OtherReceipt">{{DocumentTypeTranslations.OtherReceipt | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-template>

    <div class="five-f-details-content-container__list-item__property-container">
      <div class="five-f-details-content-container__list-item__property five-f-details-content-container__list-item__property__col-3">
        <h4 class="font-weight-bold">{{ 'DMS.SIZE' | translate }}</h4>
        <div class="mt-1 d-flex align-items-center">
          {{ _artifact?.size | dvtxFileSize }}
        </div>
      </div>

      <div class="five-f-details-content-container__list-item__property five-f-details-content-container__list-item__property__col-3">
        <h4 class="font-weight-bold">{{ 'VERSIONING_OVERVIEW.VERSION' | translate }}</h4>
        <div class="mt-1 d-flex align-items-center">
          {{ _artifact?.revision }}
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="policy?.canUpdateLabels">
    <hr>

    <div class="w-100">
      <h4 class="font-weight-bold">
        {{ 'KANBAN.LABELS_TITLE' | translate }}
        <span *ngIf="(itemLabels$ | async)?.labels?.length">({{(itemLabels$ | async)?.labels?.length}})</span>
      </h4>
      <!--<div class="d-flex align-items-center justify-content-between">-->
      <!--  <p class="font-weight-bold m-0 mr-3">{{'KANBAN.LABELS_TITLE' | translate}}</p>-->
      <dvtx-labels-selector [isTextButton]="true"
                            [labels]="labels$ | async"
                            [selected]="itemLabelsIds"
                            (onSelection)="addLabel($event)"
                            [hiddenCount]="(itemLabels$ | async)?.labels?.length">
      </dvtx-labels-selector>
      <!--</div>-->
    </div>

    <div class="col-12" *ngIf="(itemLabels$ | async)?.labels?.length">
      <div class="d-flex align-items-center">
        <dvtx-labels [showAll]="true"
                     [canShowMore]="false"
                     [labels]="(itemLabels$ | async)?.labels"
                     (onRemove)="removeLabel($event)"></dvtx-labels>
      </div>
    </div>
  </ng-container>

  <hr>

  <h4 class="font-weight-bold mb-1">{{ 'GENERAL.ASSESSMENT_PERIOD' | translate }}</h4>

  <div class="w-100 d-flex">
    <div class="five-f-mat-input">
      <ng-template [ngIf]="policy?.canChangeAssessmentPeriod" [ngIfElse]="readonlyYearRef">
        <mat-form-field style="max-width: 100px;">
          <mat-label>{{'DATE.YEAR' | translate}}</mat-label>
          <input matInput
                 type="number"
                 [formControl]="yearFormCtrl"
                 (blur)="updateAttribute(_artifact?.id, 'year', yearFormCtrl.value)" />
        </mat-form-field>
      </ng-template>

      <ng-template #readonlyYearRef>
        <div>
          <span *ngIf="_artifact?.month">{{ MONTH_I18N_KEYMAP[_artifact.month] | translate }}&nbsp;</span>
          <span *ngIf="_artifact?.year">{{ _artifact.year }}</span>

          <span *ngIf="!_artifact?.year && !_artifact?.month">{{'GENERAL.NA' | translate }}</span>
        </div>
      </ng-template>
    </div>

    <div class="ml-3 five-f-mat-input">
      <ng-template [ngIf]="policy?.canChangeAssessmentPeriod">
        <mat-form-field>
          <mat-label>{{'DATE.MONTH' | translate}}</mat-label>
          <mat-select [formControl]="monthFormCtrl"
                      [disabled]="!policy?.canChangeAssessmentPeriod"
                      (selectionChange)="updateAttribute(_artifact?.id, 'month', $event.value)" >
            <mat-option value="1">{{'DATE.JAN' | translate}}</mat-option>
            <mat-option value="2">{{'DATE.FEB' | translate}}</mat-option>
            <mat-option value="3">{{'DATE.MAR' | translate}}</mat-option>
            <mat-option value="4">{{'DATE.APR' | translate}}</mat-option>
            <mat-option value="5">{{'DATE.MAI' | translate}}</mat-option>
            <mat-option value="6">{{'DATE.JUN' | translate}}</mat-option>
            <mat-option value="7">{{'DATE.JUL' | translate}}</mat-option>
            <mat-option value="8">{{'DATE.AUG' | translate}}</mat-option>
            <mat-option value="9">{{'DATE.SEP' | translate}}</mat-option>
            <mat-option value="10">{{'DATE.OCT' | translate}}</mat-option>
            <mat-option value="11">{{'DATE.NOV' | translate}}</mat-option>
            <mat-option value="12">{{'DATE.DEC' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-template>
    </div>
  </div>

  <ng-container *ngIf="policy?.canUpdateArtifactKeywords">
    <dvtx-artifact-keyword-dropdown [artifact]="_artifact"
                                    [sectionLayout]="true"></dvtx-artifact-keyword-dropdown>
  </ng-container>

  <hr>

  <div class="d-flex align-items-center mb-1">
    <h4 class="mb-0 font-weight-bold">{{ 'GENERAL.DESCRIPTION' | translate }}</h4>
    <ng-container *ngIf="policy?.canUpdateArtifact">
      <div class="ml-3">
        <button mat-button
                color="primary"
                [disabled]="descriptionFormCtrl?.pristine"
                (click)="updateDescription(_artifact?.id)">{{ (descriptionFormCtrl?.pristine ? 'GENERAL.UPDATE_ACTION_DONE' : 'GENERAL.SAVE_ACTION') | translate }}</button>
      </div>
    </ng-container>
  </div>
  <ng-template [ngIf]="policy?.canUpdateArtifact" [ngIfElse]="readonlyDescriptionRef">
    <mat-form-field appearance="fill">
      <textarea matInput
                [formControl]="descriptionFormCtrl"
                [readonly]="!policy?.canUpdateArtifact"
                [disabled]="!policy?.canUpdateArtifact"
                (blur)="updateDescription(_artifact?.id)"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
  </ng-template>

  <ng-template #readonlyDescriptionRef>
    <textarea matInput
              [formControl]="descriptionFormCtrl"
              [readonly]="true"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="3"
              cdkAutosizeMaxRows="5"></textarea>
  </ng-template>
</div>
