<ng-container *ngIf="_folder?.info">
  <ng-container [ngSwitch]="action">
    <!-- Button to open the dialog -->
    <ng-container *ngSwitchCase="FolderInfoAction.Button">
      <button  mat-icon-button
               [color]="buttonColor"
               (click)="openFolderInfoDialog()"
               [disabled]="loading || !_folder || !_folder.info" matTooltip="{{ 'DMS.FOLDER_INFO' | translate }}">
        <mat-icon>settings</mat-icon>
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="FolderInfoAction.MenuEntry">
      <ng-content></ng-content>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template cdkPortal #title>
  <div class="dvtx-folder-info-dialog-title">
    <h2>{{ 'DMS.FOLDER_PROPERTIES' | translate }} {{ dmsFolder?.name }}</h2>
  </div>
</ng-template>

<ng-template cdkPortal #context>
  <ng-template [ngIf]="loading">
    <div style="padding: 14px; height: 100%; width: 100%" class="dvtx-loading-indicator">
      <div class="d-flex align-items-center">
        <mat-spinner [diameter]="20" style="margin-right: 15px;"></mat-spinner>
        {{ 'GENERAL.LOAD_ITEMS' | translate }}<span>.</span><span>.</span><span>.</span>
      </div>
    </div>
  </ng-template>

  <div class="dvtx-folder-info-dialog" *ngIf="!loading" style="max-height: 550px">
    <mat-tab-group [(selectedIndex)]="selectedTabIndex" dynamicHeight>
      <mat-tab>
        <ng-template mat-tab-label>
          {{ 'DMS.FOLDER_PROPERTIES' | translate }}
        </ng-template>
          <ng-container *ngIf="selectedTabIndex === 0">
          <div class="d-flex flex-column dvtx-folder-info" *ngIf="dmsFolder">
            <div style="position: relative; padding-left: 30px;">
              <span class="dvtx-folder-info-folder-icon"
                    [class.dvtx-folder-info-folder-icon-organization]="dmsFolder.dmsAccountType === DmsAccountType.Organization"
                    [class.dvtx-folder-info-folder-icon-user]="dmsFolder.dmsAccountType === DmsAccountType.Private">
                <ng-template [ngIf]="dmsFolder.shared" [ngIfElse]="notSharedFolder">
                  <mat-icon>folder_shared</mat-icon>
                </ng-template>
  
                <ng-template #notSharedFolder>
                  <mat-icon>folder</mat-icon>
                </ng-template>
              </span>
              <p *ngIf="dmsFolder.name === ''"> <strong>{{ dmsFolder?.ownedBy }}</strong> ({{ 'DMS.ROOT_FOLDER' | translate }})</p>
              <p *ngIf="dmsFolder.name !== ''"> <strong>{{ dmsFolder.name }}</strong></p>
            </div>
  
            <div class="mb-3">
              <table>
                <tr>
                  <td>{{ 'DMS.PATH' | translate }}</td>
                  <td>
                    <div class="dvtx-folder-info-path">
                      <strong>{{ dmsFolder.path === '' ? '/' : dmsFolder.path }}</strong>
                    </div>
                  </td>
                </tr>
  
                <ng-container *ngIf="dmsFolder?.admin">
                  <tr>
                    <td>{{ 'GENERAL.CREATED_AT' | translate }}</td>
                    <td><strong>{{ dmsFolder?.createdAt | date:'medium' }}</strong></td>
                  </tr>
                </ng-container>
  
                <ng-container *ngIf="dmsFolder?.admin">
                  <tr>
                    <td>{{ 'DMS.UPDATE_DATE' | translate }}</td>
                    <td><strong>{{ dmsFolder?.updatedAt | date:'medium' }}</strong></td>
                  </tr>
                </ng-container>
  
                <ng-container *ngIf="dmsFolder?.admin">
                  <tr>
                    <td>{{ 'DMS.SHARED' | translate }}</td>
                    <td><strong>{{ dmsFolder?.shared ? ('GENERAL.YES' | translate) : ('GENERAL.NO' | translate) }}</strong></td>
                  </tr>
                </ng-container>
  
                <ng-container *ngIf="dmsFolder?.admin">
                  <tr>
                    <td>{{ 'DMS.AUDIT_PROOF' | translate }}</td>
                    <td><strong>{{ dmsFolder?.auditProof ? ('GENERAL.YES' | translate) : ('GENERAL.NO' | translate) }}</strong></td>
                  </tr>
                </ng-container>
  
                <ng-container *ngIf="dmsFolder?.admin">
                  <tr>
                    <td>{{ 'DMS.DOCUMENT_COUNT' | translate }}</td>
                    <td><strong>{{ dmsFolder?.documentCount }}</strong></td>
                  </tr>
                </ng-container>
  
                <ng-container *ngIf="dmsFolder?.admin">
                  <tr>
                    <td>{{ 'DMS.SUBFOLDERS' | translate }}</td>
                    <td><strong>{{ dmsFolder?.folderCount }}</strong></td>
                  </tr>
                </ng-container>
  
                <tr>
                  <td>{{ 'PROJECT_ROOM.PROJECT_ROOMS' | translate }}</td>
                  <td><strong>{{ dmsFolder?.allProcessesSize || 0 }}</strong></td>
                </tr>
              </table>
            </div>
          </div>
        </ng-container>
      </mat-tab>

      <mat-tab [disabled]="!dmsFolder || dmsFolder.admin === false">
          <ng-template mat-tab-label>
            {{ 'DMS.FOLDER_PERMISSIONS_2' | translate }}
          </ng-template>
          <ng-container *ngIf="selectedTabIndex === 1">
          <div *ngIf="dmsFolder" style="height: 100%;">
            <div>
              <div class="d-flex justify-content-end">
                <button mat-raised-button color="primary"
                        [disabled]="saveOngoing || form.pristine || form.invalid"
                        (click)="savePermissions()">
                  {{ 'GENERAL.APPLY_CHANGE_ACTION' | translate }}</button>
              </div>
  
              <dvtx-person-autocomplete
                style="width: 100%"
                elementName="E-Mail"
                [formControl]="newPerson"
                (onSelect)="addPerson($event)"
                [dmsFolder]="dmsFolder"
                [excludedIds]="excludedIds | async">
              </dvtx-person-autocomplete>
  
              <form [formGroup]="form" novalidate>
                <ng-container formArrayName="accessors">
                  <table class="w-100 mt-1 dvtx-folder-info-permission-table">
                    <tr>
                      <th>
                        <div class="d-flex align-items-center">
                          <div>User</div>
                          <!--<div class="dvtx-inline-valign dvtx-fg-color dvtx-fg-fivef-blue-300"><mat-icon matTooltip="{{ 'DMS.READ_PERM_INFO' | translate }}">info</mat-icon></div>-->
                        </div>
                      </th>
                      <th>
                        <div class="d-flex align-items-center">
                          <div>{{ 'DMS.READ_PERM' | translate }}</div>
                          <div class="dvtx-inline-valign dvtx-fg-color dvtx-fg-fivef-blue-300"><mat-icon matTooltip="{{ 'DMS.READ_PERM_INFO' | translate }}">info</mat-icon></div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex align-items-center">
                          <div>{{ 'DMS.WRITE_PERM' | translate }}</div>
                          <div class="dvtx-inline-valign dvtx-fg-color dvtx-fg-fivef-blue-300"><mat-icon matTooltip="{{ 'DMS.WRITE_PERM_INFO' | translate }}">info</mat-icon></div>
                        </div>
                      </th>
                    </tr>
                    <tr *ngFor="let f of form.get('accessors').controls; let i = index;">
                      <ng-container *ngIf="form.get('accessors').controls[i].value as accessor" [formGroupName]="i">
                        <td>
                          <ng-container *ngIf="accessor?.email">
                            <dvtx-avatar [size]="'sm'"
                                         [profile]="avatarService.getProfile(accessor?.email)"
                                         [showEmail]="true"
                                         [showTitle]="true"></dvtx-avatar>
                          </ng-container>
  
                          <ng-container *ngIf="!accessor?.email">
                            <div class="d-flex align-items-center">
                              <div>
                                <dvtx-avatar [size]="'sm'"
                                             [profile]="{ email: null }"
                                             [showEmail]="false"
                                             [showTitle]="true"></dvtx-avatar>
                              </div>
                              <div class="align-self-center">
                                {{ accessor?.name }}
                              </div>
                              <div class="align-self-center">
                                <button mat-icon-button color="gray">
                                  <mat-icon matTooltip="{{ 'DMS.FOXDOX_ACCOUNT_WO_5F_INFO' | translate }}">info</mat-icon>
                                </button>
                              </div>
                            </div>
                          </ng-container>
                        </td>
                        <td>
                          <mat-checkbox formControlName="read" (change)="toggleWrite($event, i)"></mat-checkbox>
                        </td>
                        <td>
                          <mat-checkbox formControlName="write" (change)="toggleRead($event, i)"></mat-checkbox>
                        </td>
                      </ng-container>
                    </tr>
                  </table>
                </ng-container>
              </form>
            </div>
          </div>
        </ng-container>
      </mat-tab>

      <mat-tab [disabled]="!dmsFolder || (dmsFolder?.allProcessesSize && dmsFolder.allProcessesSize === 0)">
          <ng-template mat-tab-label>
            {{ 'PROJECT_ROOM.PROJECT_ROOMS' | translate }} ({{ dmsFolder?.allProcessesSize }})
          </ng-template>
          <ng-container *ngIf="selectedTabIndex === 2">
          <div *ngIf="dmsFolder" style="height: 100%;">
            <h2 class="mt-2">{{ 'DMS.PROJECT_ROOMS_WITH_ACCESS' | translate }}</h2>
            <div>
              {{ 'DMS.WITH_ACCESS_PERMISSION' | translate }}: <strong>{{ dmsFolder.userProcessesSize }}</strong>
              &nbsp;|&nbsp;
              {{ 'DMS.SUM' | translate }}:  <strong>{{ dmsFolder.allProcessesSize }}</strong>
            </div>
  
            <div class="mt-2">
              <dvtx-simple-process-table [loading]="loading"
                                         (onClick)="navigateToProcess($event)"
                                         [processData]="dmsFolder.userProcessMap"></dvtx-simple-process-table>
            </div>
          </div>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-template>

<ng-template cdkPortal #buttonsToolbar>
  <div class="d-flex justify-content-between w-100">
    <div>
      <ng-container *ngIf="process?.id">
        <button mat-button color="primary" (click)="goToPermissionView()">
          {{ 'AUTH.PERMISSIONS' | translate }}
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </ng-container>
    </div>
    <div>
      <button mat-raised-button (click)="closeDialog()">
        {{ 'GENERAL.CLOSE_ACTION' | translate }}
      </button>
    </div>
  </div>
</ng-template>
