import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {EmailValidator} from 'app/lib/validator';
import {StringUtils} from 'app/lib/string_utils';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {Sorters} from 'app/lib/sorter/sorters';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogParticipantComponent } from 'app/shared/components/dialogs/confirmation-dialog-participant/confirmation-dialog-participant.component';
import { ProcessParticipant } from 'app/+store/process-participant/process-participant';

@Component({
  selector: 'dvtx-person-selector-autocomplete',
  templateUrl: './person-selector-autocomplete.component.html',
  styleUrls: ['./person-selector-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonSelectorAutocompleteComponent implements OnDestroy {
  private _onDestroy = new Subject<void>();
  @ViewChild('dropdown') public dropdown: CdkOverlayOrigin;

  public searchValue: string;
  public allPersons: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public filteredPersons: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public showDropdown = false;
  _selectedProfile: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _selectedProfiles: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  _selectedProfilesEmails: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  searchTerm = '';
  searchTermIsEmail = false;
  @Input() maxToShow = 3;
  @Input() acceptTypedEmail = false;
  @Input() avatarSize = 'sm';
  @Input() responsibleCollectoRemove = false;
  @Input() set persons(persons) {
    if (persons) {
      // not to take reference for it to prevent addition of the "RemoveCollectoResponsibility" record in the global variable
      this.allPersons.next(JSON.parse(JSON.stringify(persons)));
      // this.filteredPersons.next(persons);
      if (this.responsibleCollectoRemove && this.allPersons
        && this.allPersons.value &&  this.allPersons.value.length > 0
        && !this.allPersons.value.find(a => a.id === 'RemoveCollectoResponsibility')) {
        const removeParticipant = PersonSelectorAutocompleteComponent.getRemoveCollectoResponsibilityUser();
       this.allPersons.value.unshift(removeParticipant)
      } else if (!this.responsibleCollectoRemove && this.allPersons.value.find(a => a.id === 'RemoveCollectoResponsibility')) {
        this.allPersons.next(this.allPersons.value.filter(a => a.id !== 'RemoveCollectoResponsibility'));
      }
    }
  };

  @Input() icon = 'add_circle';

  @Input() set selectedProfile(selectedProfile) {
    if (selectedProfile) {
      this._selectedProfile.next(selectedProfile);

      const combinedContacts = this.conbineContactsWithExternals([selectedProfile]);

      this.allPersons.next(combinedContacts);

      // this.filteredPersons.next(
      //   this.allPersons.value.filter((person) => person.email !== this.selectedProfile.email)
      // );
    } else {
      this._selectedProfile.next(selectedProfile);
    }
  };

  selectedEmailMap = {};

  @Input() set selectedProfiles(selectedProfiles) {
    if (selectedProfiles) {
      let selectedEmails = selectedProfiles || [];
      selectedEmails = selectedEmails.filter(p => !!p);

      let _selectedProfiles = [];

      if (selectedEmails && (typeof selectedEmails[0] === 'string' || selectedEmails[0] instanceof String)) {
        _selectedProfiles = selectedEmails.map(email => {
          return {email: email}
        }).filter(p => !!p.email);
      } else {
        _selectedProfiles = selectedEmails.filter(p => !!p.email);
      }
      _selectedProfiles = _selectedProfiles.sort((l, r) => Sorters.sort(l.email, r.email));

      this._selectedProfiles.next(_selectedProfiles);
      this._selectedProfilesEmails.next([]);

      this.selectedEmailMap = {};
      selectedEmails.forEach(email => {
        if (typeof (email) === 'string' && !!email && email !== '') {
          this.selectedEmailMap[StringUtils.normalizeEmail(email)] = true;
        } else if ('email' in email && !!email.email && email.email !== '') {
          this.selectedEmailMap[StringUtils.normalizeEmail(email.email)] = true;
        }
      });
      const combinedContacts = this.conbineContactsWithExternals(selectedEmails);

      this._selectedProfilesEmails.next(selectedEmails);

      this.allPersons.next(combinedContacts);

      combinedContacts.forEach(
        (person) => {
          const selected = selectedEmails.find(selected => person && selected === person.email && !this.selectedProfiles.includes(selected));
          if (selected) {
            this._selectedProfilesEmails.next([...this.selectedProfiles, selected.email]);
          }
        }
      );
    }
  };

  @Input() title;
  @Input() multipeSelection = false;
  @Output() onSelection: EventEmitter<any> = new EventEmitter<any>(null);
  @Output() onUnSelection: EventEmitter<any> = new EventEmitter<any>(null);

  get selectedProfile() {
    return this._selectedProfile.value;
  }

  get selectedProfiles() {
    return this._selectedProfilesEmails.value;
  }

  @Input() disabled = false;
  @Input() enableConfirmation = false;
  @Input() enableRecursive = true;
  @Input() enableAdmin = true;

  @ViewChild('searchSelectInput', {read: ElementRef}) searchSelectInput: ElementRef;
  @ViewChild('firstLi') firstLi: ElementRef;

  constructor(private avatarService: AvatarService, public _cdr: ChangeDetectorRef,
    private dialog: MatDialog) {
  }


  openConfirmationSelectPerson(person, $event) {
    if (!person || !person.email) return;

    if (this.disabled) {
      return false;
    }

    const email = StringUtils.normalizeEmail(person.email);

    // Use selectPerson instead.
    if (!this.enableConfirmation) {
      return;
    }

    $event.preventDefault();

    if (!this.selectedEmailMap[email]) {
      this.dialog.open(ConfirmationDialogParticipantComponent, {
        disableClose: true,
        data: {
          title: 'GENERAL.ADD_PARTICIPANT',
          message: 'GENERAL.ADD_PARTICIPANT_CONFIRMATION',
          submitButtonTitle: 'GENERAL.CONFIRM_ACTION',
          cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
          person: person,
          adminOption: this.enableAdmin,
          recursiveOption: this.enableRecursive,
          onSubmitAction: (recursive, admin) => {
            const matChecked = { checked: true };
            this.confirmPerson(person, matChecked, recursive, admin);
          },
          onCancelAction: () => {
          }
        }
      });
    } else {
      this.dialog.open(ConfirmationDialogParticipantComponent, {
        data: {
          title: 'GENERAL.REMOVE_PARTICIPANT',
          message: 'GENERAL.REMOVE_PERSON_PARTICIPANT',
          submitButtonTitle: 'GENERAL.REMOVE_ACTION',
          cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
          color: '#db0000',
          remove: true,
          person: person,
          onSubmitAction: () => {
            const matChecked = { checked: false };
            this.confirmPerson(person, matChecked);
          },
          onCancelAction: () => {
          }
        }
      });
    }
  }

  selectPerson(person, $event: any = null) {
    if (!person || !person.email) return;

    if (this.disabled) {
      return false;
    }

    // Use confirmPerson/openConfirmationSelectPerson instead.
    if (this.enableConfirmation) {
      return;
    }

    const email = StringUtils.normalizeEmail(person.email);
    const foundedPerson = this.allPersons.value.find(p => person && StringUtils.normalizeEmail(p.email) == email);
    if (this.searchTerm && !this.searchTermIsEmail && !foundedPerson) {
      return false;
    }
    if (this.multipeSelection) {
      if ($event.checked) {
        this._selectedProfilesEmails.next([...this.selectedProfiles, person.email]);
        this.selectedEmailMap[email] = true;
        this.onSelection.emit(email);
      } else {
        this._selectedProfilesEmails.next(this.selectedProfiles.filter(selected => selected.email !== person.email));
        this.selectedEmailMap[email] = null;
        this.onUnSelection.emit(email);
      }
    } else {
      this.showDropdown = false;
      this.onSelection.emit(email);
    }
  }

  private confirmPerson(person, $event: any = null, recursive = false, admin = false) {
    if (!person || !person.email) return;

    if (this.disabled) {
      return false;
    }
    const email = StringUtils.normalizeEmail(person.email);
    const foundedPerson = this.allPersons.value.find(p => person && StringUtils.normalizeEmail(p.email) == email);
    if (this.searchTerm && !this.searchTermIsEmail && !foundedPerson) {
      return false;
    }
    if (this.multipeSelection) {
      if ($event.checked) {
        this._selectedProfilesEmails.next([...this.selectedProfiles, person.email]);
        this.selectedEmailMap[email] = true;
        this.onSelection.emit({email: email, recursive: recursive, admin: admin});
      } else {
        this._selectedProfilesEmails.next(this.selectedProfiles.filter(selected => selected.email !== person.email));
        this.selectedEmailMap[email] = null;
        this.onUnSelection.emit(email);
      }
    } else {
      this.showDropdown = false;
      this.onSelection.emit({email: email, recursive: recursive, admin: admin});
    }
  }

  /**
   * Handles the key down event with MatSelect.
   * Allows e.g. selecting with enter key, navigation with arrow keys, etc.
   * @param {KeyboardEvent} event
   * @private
   */
  _handleKeydown(event: KeyboardEvent) {
    if (event.keyCode === 32) {
      // do not propagate spaces to MatSelect, as this would select the currently active option
      event.stopPropagation();
    }
  }

  onInputChange(value) {
    // reset search on empty text
    // if (!value) return;
    this.filteredPersons.next(this.allPersons.value.filter(p => !!p && p.email));
    this.searchValue = value.trim ? value.trim().toLowerCase() : '';
    this.searchTerm = this.searchValue;
    this.searchTermIsEmail = EmailValidator.emailValid(this.searchTerm);
    if (!this.searchTerm || !this.allPersons) {
      return;
    }

    this.filteredPersons.next(
      this.filteredPersons.value.filter(person => {
        if (!person) return false;

        const firstNameMatch = person && person.firstName && person.firstName.toLowerCase().indexOf(this.searchTerm) > -1;
        const lastNameMatch = person && person.lastName && person.lastName.toLowerCase().indexOf(this.searchTerm) > -1;
        const emailMatch = person && person.email && person.email.toLowerCase().indexOf(this.searchTerm) > -1;
        return firstNameMatch || lastNameMatch || emailMatch || person.id === 'RemoveCollectoResponsibility';
      })
    )
  }

  onDropdownOpen() {
    if (this.responsibleCollectoRemove && this.allPersons && this.allPersons.value &&  this.allPersons.value.length > 0) {
      for (let i = 0; i < this.allPersons.value.length ; i++ ) {
      if (this.allPersons.value[i].id === 'RemoveCollectoResponsibility') {
        if (this.selectedProfile) {
          this.allPersons.value[i].firstName = this.selectedProfile.firstName;
          this.allPersons.value[i].lastName = this.selectedProfile.lastName;
          this.allPersons.value[i].name = this.selectedProfile.name;
        } else {
          this.allPersons.value[i].firstName = null;
          this.allPersons.value[i].lastName = null;
          this.allPersons.value[i].name = null;
        }
        this._cdr.detectChanges();
      }
    }
  }
    if (this.selectedProfile && this.selectedProfile.email) {
      this.filteredPersons.next(
        this.allPersons.value.filter((person) => !!person && person.email !== this.selectedProfile.email)
      );
    } else {
      this.filteredPersons.next(this.allPersons.value.filter(p => !!p && p.email));
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.allPersons.complete();
    this.filteredPersons.complete();
    this._selectedProfile.complete();
    this._selectedProfiles.complete();
    this._selectedProfilesEmails.complete();
  }

  conbineContactsWithExternals(selectedProfiles) {
    const combinedContacts = this.allPersons.value.concat(selectedProfiles);
    for (let i = 0; i < combinedContacts.length; ++i) {
      for (let j = i + 1; j < combinedContacts.length; ++j) {
        if (combinedContacts[i].email === combinedContacts[j].email)
          combinedContacts.splice(j--, 1);
      }
    }

    return combinedContacts;
  }

  public openSelector() {
    this.showDropdown = true;
    this.onDropdownOpen();
    this._cdr.detectChanges();
    if (this.firstLi) {
      setTimeout(() => {
        this.firstLi.nativeElement.focus();
      }, 200);
    }
  }

  public static getRemoveCollectoResponsibilityUser() {
    return new ProcessParticipant('RemoveCollectoResponsibility', 'RemoveCollectoResponsibility', null , 'RemoveCollectoResponsibility',
      null, null, null, null, undefined );
  }
}
