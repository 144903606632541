<!-- Top backlink. Currently points to the main user profile overview.  -->
<div *ngIf="backLink" class="button-row mb-2">
  <a mat-button [routerLink]="'/user-profile/navigation'">
    <mat-icon matAffix aria-label="back">arrow_back</mat-icon>
    {{ 'GENERAL.NAV_TO_OVERVIEW' | translate }}
  </a>
</div>

<!-- Title section -->
<div class="w-100 pl-3 mb-1 d-flex justify-content-between">
  <div>
    <h1>{{ title | translate }}</h1>
    <h5 *ngIf="subtitle">{{ subtitle | translate }}</h5>

    <!-- User Profile (optional) -->
    <h5 class="mt-0 d-flex align-items-center" *ngIf="showUserProfile">
      <dvtx-avatar [size]="'xs'" [profile]="person$ | async"
                   matTooltip="{{ 'BANNER.TOOLTIP.SIGNED_IN_AS' | translate:{ user: user?.email } }}"
                   [matTooltipPosition]="'left'"></dvtx-avatar>
      <div class="ml-1">
        {{ user?.name }}
        <span class="dvtx-fg-color dvtx-fg-fivef-blue-300"> ({{user?.email}})</span>
      </div>
    </h5>
  </div>

  <!-- Right aligned optional call to action button (optional) -->
  <div *ngIf="callToActionPortal">
    <ng-template [cdkPortalOutlet]="callToActionPortal"></ng-template>
  </div>
</div>

<!-- Tab and search bar -->
<div class="w-100 d-flex justify-content-between align-items-center dvtx-user-profile-settings-header">
  <dvtx-organization-card-nav-bar-light [routes]="routes" [activeLink]="activeLink"></dvtx-organization-card-nav-bar-light>

  <!-- Right aligned optional search -->
  <div class="d-flex">
    <div class="five-f-mat-input five-f-search" *ngIf="enableSearch">
      <mat-form-field class="five-f-search"
                      style="margin-bottom: -2rem;"
                      (keydown.enter)="$event.preventDefault()">
        <input type="text" [placeholder]="'GENERAL.SEARCH' | translate"
               [(ngModel)]="searchTerm"
               (keyup)="applySearch(searchFieldRef.value)" matInput #searchFieldRef>
        <mat-icon matPrefix>search</mat-icon>

        <button mat-button *ngIf="searchFieldRef?.value"
                matSuffix mat-icon-button
                color="gray"
                [disableRipple]="true"
                aria-label="Clear" (click)="applySearch(''); searchFieldRef.value = ''; $event.stopPropagation()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <!-- Right aligned action portal e.g. for three dots menu -->
    <div *ngIf="globalActionPortal">
      <ng-template [cdkPortalOutlet]="globalActionPortal"></ng-template>
    </div>
  </div>
</div>
