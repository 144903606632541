import {IResource} from 'app/shared/modules/api/models/resource.interface';
import { BomNodeDataColumn } from '../process-management/process-management';
import { IColumnSetup } from 'app/modules/quickcollector-v2/components/items-listing/items-listing.repository';

export class WorkflowTemplate implements IResource {
  readonly type = 'workflow_template';


  total: number;
  perPage: number;
  records: number;

  constructor(public id: string,
              public templateType: TemplateType,
              public title: string = '',
              public description: string = '',
              public visibility: TemplateVisibility,
              public clients: string[],
              public creator: string,
              public color: string,
              public createdAt: Date,
              public data?: CollectoTemplateData | ProjectTemplateData,
              public updatedAt?: Date,
              public categoryId?: string,
              public lockedAt?: Date,
              public blockedAt?: Date,
              public statistics?: { usageCount: number },
              public history?: { performerId: string },) {
  }
}

export enum TemplateType {
  Collecto = 'collecto',
  Project = 'project',
}

export enum TemplateStatus {
  Open = 0,
  Blocked = 1,
  Global = 2,
  Personal = 3
}

export enum TemplateVisibility {
  Hidden = 'hidden',
  Global = 'global',
  Personal = 'personal',
}

export enum TemplateLoadingScope {
  Personal = 'personal',
  Organizational = 'admin',
}

export interface CollectoTemplateData {
  children: [
    {
      id: string,
      title: string,
      color?: string,
      items: [
        {
          id: string,
          title: string,
          due_date?: string,
          description?: string,
          priority?: string
        }
      ]
    }
  ]
  /**
   * @deprecated Use [children] instead of categories, kept for backward compatibility
   */
  categories: [
    {
      id: string,
      title: string,
      color?: string,
      items: [
        {
          id: string,
          title: string,
          due_date?: string,
          description?: string,
          priority?: string
        }
      ]
    }
  ]

  columnSetup: BomNodeDataColumn[]
}

export interface ProjectTemplateData {
  title: string,
  color: string,
  icon: string,
  isSvgIcon: boolean,
  description: string,
  dmsFolderId: string,
  type: string,
  subStorage?: string,
  children: ProjectTemplateData[]
  columnSetup?: any
}

export interface ITemplateParams {
  templateType?: TemplateType;
  title?: string;
  description?: string;
  status?: TemplateStatus;
  visibility?: TemplateVisibility;
  clients?: string[];
  creator?: string;
  color?: string;
  data?: CollectoTemplateData | ProjectTemplateData;
  categoryId?: string;
  published?: boolean;
}

export interface IProjectTemplateParams {
  title: string;
  description: string;
  dueDate: Date;
  clientId: string;
  color: string;
  dmsFolderId: string;
  dmsAccountType: string;
  children: ProjectTemplateData[];
}
