import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import * as model from './organization';

export namespace Operator {
  export class OrganizationStatsBuilder implements IApiResourceBuilder<model.Operator.OrganizationStats> {
    fromResponse(data): model.Operator.OrganizationStats {
      return new model.Operator.OrganizationStats(data.attributes.trial_phase, data.attributes.booked_licence_plan, data.attributes.overdue);
    }

    toRequest(org: model.Operator.OrganizationStats) {
      return null;
    }
  }

  export class OrganizationBuilder implements IApiResourceBuilder<model.Operator.Organization> {
    fromResponse(data): model.Operator.Organization {
      const org = new model.Operator.Organization(
        data.id,
        data.attributes.name,
        data.attributes.country_code,
        data.attributes.created_at,
        data.attributes.updated_at);
      org.cmsEnabledAt = data.attributes.cms_enabled_at;
      org.reportsEnabledAt = data.attributes.reporting_enabled_at;
      org.auditProofDmsEnabledAt = data.attributes.audit_proof_dms_enabled_at;
      org.documentRevisionEnabledAt = data.attributes.document_revision_enabled_at;
      org.processManagementEnabledAt = data.attributes.process_management_enabled_at;
      org.motdEnabledAt = data.attributes.motd_enabled_at;
      org.labelsEnabledAt = data.attributes.labels_enabled_at;
      org.kanbanEnabledAt = data.attributes.kanban_enabled_at;
      org.fastdocsEnabledAt = data.attributes.fastdocs_enabled_at;
      org.digitalbarEnabledAt = data.attributes.digitalbar_enabled_at;
      org.fivefAgentEnabledAt = data.attributes.fivef_agent_enabled_at;
      org.datevEnabledAt = data.attributes.datev_enabled_at;
      org.fivefDesktopEnabledAt = data.attributes.fivef_desktop_enabled_at;
      org.datevDmsEnabledAt = data.attributes.datev_dms_enabled_at;
      org.exportMarkerEnabledAt = data.attributes.export_marker_enabled_at;
      org.mobileEnabledAt = data.attributes.mobile_enabled_at;
      org.japEnabledAt = data.attributes.jap_enabled_at;
      org.kapEnabledAt = data.attributes.kap_enabled_at;
      org.bookmanEnabledAt = data.attributes.bookman_enabled_at;
      org.bigFileSupportEnabledAt = data.attributes.big_file_enabled_at;
      org.partnerLinksEnabledAt = data.attributes.partner_links_enabled_at;
      org.contractStartedAt = data.attributes.contract_started_at;
      org.processCount = data.attributes.process_count;
      org.licence = data.attributes.licence;
      org.edition = data.attributes.edition;
      org.namedUserLicences = data.attributes.named_user_licences;
      org.freeNamedUserLicences = data.attributes.free_named_user_licences;
      org.dmsSid = data.attributes.dms_sid;
      org.dmsUsername = data.attributes.dms_username;
      org.dmsEmail = data.attributes.dms_email;
      org.status = data.attributes.status;

      org.tenantId = data.attributes.tenant_id;
      org.tenantName = data.attributes.tenant_name;
      org.tenantDomain = data.attributes.tenant_domain;

      org.adDepartments = data.attributes.ad_departments;

      org.customerName = data.attributes.customer_name;
      org.customerNumber = data.attributes.customer_number;
      org.note = data.attributes.note;

      org.memberships = [];
      if (data.attributes.memberships && data.attributes.memberships.length) {
        data.attributes.memberships.forEach(member => {
          org.memberships.push({
            id: member.id,
            name: member.name,
            email: member.email,
            fastdocsEnabledAt: member.fastdocs_enabled_at,
            membershipId: member.membership_id
          });
        });
      }

      if (data.attributes.dms_folder_info) {
        org.dmsHumanSize = data.attributes.dms_folder_info.human_size;
        org.dmsUsedInPercent = data.attributes.dms_folder_info.used_in_percent;
        org.dmsDiskSpace = data.attributes.dms_folder_info.disk_space;
        org.dmsFree = data.attributes.dms_folder_info.free;
        org.dmsDocumentCount = data.attributes.dms_folder_info.document_count;
        org.dmsFolderCount = data.attributes.dms_folder_info.folder_count;
        org.usedInHumanSize = data.attributes.dms_folder_info.used_in_human_size;
      }
      return org;
    }

    toRequest(org: model.Operator.Organization) {
      return {
        data: {
          id: org.id,
          type: org.type,
          attributes: {
            cms_enabled: !!org.cmsEnabledAt,
            bookman_enabled: !!org.bookmanEnabledAt,
            fastdocs_enabled: !!org.fastdocsEnabledAt,
            digitalbar_enabled: !!org.digitalbarEnabledAt,
            fivef_agent_enabled: !!org.fivefAgentEnabledAt,
            datev_enabled: !!org.datevEnabledAt,
            datev_dms_enabled: !!org.datevDmsEnabledAt,
            fivef_desktop_enabled: !!org.fivefDesktopEnabledAt,
            big_file_enabled: !!org.bigFileSupportEnabledAt,
            jap_enabled: !!org.japEnabledAt,
            kap_enabled: !!org.kapEnabledAt,
            labels_enabled: !!org.labelsEnabledAt,
            kanban_enabled: !!org.kanbanEnabledAt,
            audit_proof_dms_enabled: !!org.auditProofDmsEnabledAt,
            partner_links_enabled: !!org.partnerLinksEnabledAt,
            process_management_enabled: !!org.processManagementEnabledAt,
            document_revision_enabled: !!org.documentRevisionEnabledAt,
            export_marker_enabled: !!org.exportMarkerEnabledAt,
            reports_enabled: !!org.reportsEnabledAt,
            mobile_enabled: !!org.mobileEnabledAt
          }
        }
      };
    }
  }

  export class EmailFooterBuilder implements IApiResourceBuilder<model.Operator.EmailFooter> {
    fromResponse(data): model.Operator.EmailFooter {
      return new model.Operator.EmailFooter(
        data.id,
        data.attributes.title,
        data.attributes.content,
        data.attributes.creator_email,
        data.attributes.published_at,
        data.attributes.updated_at,
        data.attributes.created_at,
        !!data.attributes.published_at
      );
    }

    toRequest (footer: model.Operator.EmailFooter) {
      return {
        data: {
          id: footer.id,
          type: 'email_footers',
          attributes: {
            title: footer.title,
            content: footer.content,
            published: footer.published,
          }
        }
      };
    }
  }

  export class OrganizationLogoBuilder implements IApiResourceBuilder<model.Operator.OrganizationLogo> {
    fromResponse(data): model.Operator.OrganizationLogo {
      return new model.Operator.OrganizationLogo(
        data.id,
        data.attributes.link,
        data.attributes['default'],
        data.attributes.title,
        data.attributes.organization_uuid,
        data.attributes.inviter_logo,
        data.attributes.created_at
      );
    }

    toRequest(_: model.Operator.OrganizationLogo) {
      return null
    }
  }

  export class VisualSignatureBackgroundBuilder implements IApiResourceBuilder<model.Operator.VisualSignatureBackground> {
    fromResponse(data): model.Operator.VisualSignatureBackground {
      return new model.Operator.VisualSignatureBackground(
        data.id,
        data.attributes.content,
        data.attributes.font_color,
        data.attributes.updated_at,
        data.attributes.created_at
      );
    }

    toRequest(visual: model.Operator.VisualSignatureBackground) {
      return {
        data: {
          id: visual.id,
          type: visual.type,
          attributes: {
            content: visual.content,
            font_color: visual.fontColor
          }
        }
      }
    }
  }

  export class OrganizationOnboardingBuilder implements IApiResourceBuilder<model.Operator.OrganizationOnboarding> {
    fromResponse(data): model.Operator.OrganizationOnboarding {
      return new model.Operator.OrganizationOnboarding(
        data.id,
        data.attributes.organization_id,
        data.attributes.organization_name,
        data.attributes.operator_email,
        data.attributes.operator_first_name,
        data.attributes.operator_last_name,
        data.attributes.licence_count,
        data.attributes.licence_type,
        data.attributes.updated_at,
        data.attributes.created_at
      );
    }

    toRequest(onboarding: model.Operator.OrganizationOnboarding) {
      return {
        data: {
          id: onboarding.id,
          type: onboarding.type,
          attributes: {
            organization_id: onboarding.organization_id
          }
        }
      }
    }
  }
}
