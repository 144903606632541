import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, BehaviorSubject, timer, of} from 'rxjs';
import {UntypedFormBuilder} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {RegistrationUser} from '../../models/registration-user.interface';
import {RegistrationService} from '../../services/registration.service';
import {catchError, filter, finalize, first, map, switchMap, takeUntil} from 'rxjs/operators';
import {FoxdoxService} from '../../../foxdox/services/foxdox.service';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {LoginUserRequest, UserLogoutError, UserLogoutSuccess} from 'app/actions/user-actions';
import {AngularTokenService} from 'angular-token';
import {environment} from 'environments/environment';

export enum RegistrationState {
  'Password' = 'Password',
  'Setup' = 'Setup',
  'InvalidToken' = 'InvalidToken',
}

@Component({
  selector: 'dvtx-registration-setup',
  templateUrl: './registration-setup.component.html',
  styleUrls: ['./registration-setup.component.scss']
})
export class RegistrationSetupComponent implements OnDestroy, OnInit {
  onDestroy = new Subject<void>();
  RegistrationState = RegistrationState;

  user: RegistrationUser;
  error: String;

  invType: Observable<string>;

  state: RegistrationState = RegistrationState.Password;
  dmsAccountPresent$ = new BehaviorSubject<RegistrationUser>(null);
  accountCompleted$ = new BehaviorSubject<RegistrationUser>(null);

  infoMsg: string;
  cavRedirect = false;

  constructor(private _formBuilder: UntypedFormBuilder,
              private _activatedRoute: ActivatedRoute,
              private _router: Router,
              private _registrationSvc: RegistrationService,
              private _foxdoxSvc: FoxdoxService,
              private _tokenSvc: AngularTokenService,
              private _store: Store<AppState>) {
  }

  ngOnInit() {
    this.invType = this._activatedRoute.queryParamMap.pipe(takeUntil(this.onDestroy), map(x => x.get('invitation_type')));
    this._activatedRoute.params.pipe(takeUntil(this.onDestroy), ).subscribe((params: Params) => {
      this.verifyToken(params['token']);
    });

    this.infoMsg = this._activatedRoute.snapshot.queryParams['info'];
    this.cavRedirect = this.infoMsg === 'cav_redirect';

    this.dmsAccountPresent$
      .pipe(takeUntil(this.onDestroy), filter(doIt => !!doIt))
      .subscribe(user => {
        this.completeAccount(user);
      });

    if (this._tokenSvc.userSignedIn()) {
      this._tokenSvc.signOut()
        .pipe(
          first(),
          switchMap((res) => {
            if (!environment.production) {
              console.log('UserLogoutRequest: Logging out');
            }
            return [new UserLogoutSuccess()];
          }),
          catchError(error => {
            if (error.status && error.status !== 404) {
              console.error('UserLogoutRequest: Failed logging out or already logged out: Clearing store, navigating to session/sign-in');
            }
            return of(new UserLogoutError(error));
          }),
          finalize(() => {
            localStorage.removeItem('selectedOrganizationId');
            localStorage.clear();
            try {
              this._store.dispatch({ type: null });
            } catch (e) {
              this._store.dispatch({ type: null });
              console.error(e);
            }
          })
        )
        .subscribe(res => {
          setTimeout(_ => this._userDashboardRouteListener(), 1)
        }, err => {
          setTimeout(_ => this._userDashboardRouteListener(), 1)
          console.error(err);
        })
    } else {
      setTimeout(_ => this._userDashboardRouteListener(), 1)
    }
  };

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  _userDashboardRouteListener() {
    this._store.select('currentUser')
      .pipe(takeUntil(this.onDestroy), filter(user => !!user))
      .subscribe(user => {
        this._router.navigate(['dashboard']);
      });
  }

  verifyToken(token: string) {
    this._registrationSvc.verifyToken(token).subscribe(response => {
      if (!response) {
        return;
      }
      if (response.success) {
        this.user = response.user;
        this.user.confirmationToken = token;
      } else {
        this.error = response.message;
        this.state = RegistrationState.InvalidToken;
      }
    });
  }

  toSetupPage(user: RegistrationUser) {
    const _user = user;
    this._registrationSvc.update(_user).subscribe(res => {
      this.state = RegistrationState.Setup;
      this.user = _user;
      setTimeout(_ => {
        this.createDmsAccount(_user);
      });
    }, err => {
      console.error(err);
    });
  }

  toPasswordPage() {
    this.state = RegistrationState.Password;
  }

  completeSetup() {
    // this.dmsAccountPresent$.next(null);
    // this.accountCompleted$.next(null);
    this._store.dispatch(new LoginUserRequest({email: this.user.email, password: this.user.password}));
  }

  createDmsAccount(user: RegistrationUser) {
    const _user = user;
    // Debug:
    // const source = timer(4000);
    // source.subscribe(_ => {
    //   this.dmsAccountPresent$.next(_user);
    // });
    this._foxdoxSvc.create(_user).subscribe(res => {
      console.error(res);
      this.dmsAccountPresent$.next(_user);
    }, err => {
      this.dmsAccountPresent$.next(_user);
      console.error(err);
    });
  }

  completeAccount(user: RegistrationUser) {
    const _user = user;
    // Debug:
    // const source = timer(4000);
    // source.subscribe(_ => {
    //   this.accountCompleted$.next(_user);
    // });
    this._registrationSvc.completeRegistration(_user).pipe(first()).subscribe(res => {
      this.accountCompleted$.next(_user);
    }, err => {
      this.accountCompleted$.next(_user);
      console.error(err);
    })
  }
}
