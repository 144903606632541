<div class="dvtx-list-choice-select">
  <div class="d-flex">
    <mat-radio-group (change)="onSelect($event?.value)"
                     [value]="choiceValue"
                     [disabled]="disabled"
      class="justify-content-between align-content-center">
      <ng-container *ngFor="let item of choiceList;let i = index;">
        <mat-radio-button [value]="i" [disableRipple]="true">
          <span>{{item | translate}}</span>
        </mat-radio-button>
      </ng-container>

      <div class="dvtx-list-choice-select--edit-button" *ngIf="editable">
        <button mat-icon-button color="lightgray" (click)="onChangeRequest.emit()">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </mat-radio-group>
  </div>

  <!--<div class="dvtx-list-choice-select--edit"
       *ngIf="editable">
    <small>
      <a href="#" (click)="$event.preventDefault(); onChangeRequest.emit()">
        {{ 'GENERAL.CHANGE' | translate }}
      </a>
    </small>
  </div>-->
</div>
