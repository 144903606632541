import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DocumentType, DocumentTypeTranslations, ProcessArtifact} from 'app/+store/process-artifact/process-artifact';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {ArtifactProperty, ProcessArtifactService} from 'app/+store/process-artifact/process-artifact.service';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {
  FeatureSelectors,
  ItemLabelsActions,
  ItemLabelsSelectors,
  LabelActions,
  LabelSelectors,
  ProcessArtifactActions, ProcessArtifactSelectors
} from 'app/+store';
import {Observable} from 'rxjs/internal/Observable';
import {Feature} from 'app/+store/feature/feature';
import {Label} from 'app/+store/label/label';
import {ItemLabels} from 'app/+store/item-labels/item-labels';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {catchError, filter, first, map, switchMap, takeUntil} from 'rxjs/operators';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {GLOBAL_LABELS} from 'app/five-f/labels/configs';
import {Subject} from 'rxjs/internal/Subject';
import {ItemType} from 'app/+store/item-labels/item-labels.interface';
import {of} from 'rxjs/internal/observable/of';
import {LabelService} from 'app/+store/label/label.service';
import * as api from 'app/+store/iam/process-policy.interface';
import {ProcessService} from 'app/+store/process/process.service';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {
  MONTH_I18N_KEYMAP
} from 'app/shared/modules/priority-selector/component/priority-selector.component';

@Component({
  selector: 'dvtx-artifact-details',
  templateUrl: './artifact-details.component.html',
  styleUrls: ['./artifact-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArtifactDetailsComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  public MONTH_I18N_KEYMAP = MONTH_I18N_KEYMAP;
  public DocumentType = DocumentType;
  public DocumentTypeTranslations = DocumentTypeTranslations;

  private artifactId$ = new BehaviorSubject<string>(null);
  private processId$ = new BehaviorSubject<string>(null);
  public _artifact: ProcessArtifact

  public _processId: string;

  public featureSet$: Observable<Feature>;

  // Label setup.
  public labels$: Observable<Label[]>;
  public itemLabels$: Observable<ItemLabels>;
  private itemLabelsIds$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public itemLabelsIds: string[] = [];

  public policy: api.Iam.IProcessPolicy = api.Iam.DEFAULT_PROCESS_POLICY;

  public descriptionFormCtrl = new UntypedFormControl();
  public yearFormCtrl = new UntypedFormControl();
  public monthFormCtrl = new UntypedFormControl();
  public roleFormCtrl = new UntypedFormControl();
  public documentTypeFormCtrl = new UntypedFormControl();

  @Input()
  private set artifact(artifact: ProcessArtifact) {
    this.artifactId$.next(artifact ? artifact.id : null);

    // Note: the processId of an artifact is not necessarily reflecting the process
    //       by which the user gained access to the artifact, but mostly.
    //       Reasons: The original process could already have been deleted, the
    //                artifact could have been relinked to some other process.
    if (artifact && artifact.processIds && artifact.processIds.includes(artifact.processId)) {
      this.processId$.next(artifact.processId);
      this._processId = artifact.processId;
    } else {
      this._processId = artifact.processIds ? artifact.processIds[0] : null;
    }
  }

  constructor(public avatarService: AvatarService,
              private store: Store<AppState>,
              private fb: UntypedFormBuilder,
              private artifactSvc: ProcessArtifactService,
              private processSvc: ProcessService,
              private labelsSvc: LabelService,
              private cdr: ChangeDetectorRef,
              private notifyService: NotificationService) {
  }

  ngOnInit(): void {
    this.featureSet$ = this.store.select(FeatureSelectors.getCurrentFeatureSet);

    this.labels$ = this.store.select(LabelSelectors.getAllExceptContextual);
    const itemLabels$ = this.artifactId$.pipe(switchMap(id => this.store.select(ItemLabelsSelectors.getByItemId(id))));

    this.artifactId$
      .pipe(filter(aid => !!aid), switchMap(id => this.store.select(ProcessArtifactSelectors.getProcessArtifactById(id))))
      .subscribe(artifact => {
        this._artifact = artifact;

        if (artifact) {
          this.yearFormCtrl.patchValue(artifact.year);
          this.monthFormCtrl.patchValue('' + artifact.month);
          this.descriptionFormCtrl.patchValue(artifact.description);
          this.roleFormCtrl.patchValue(artifact.role);
          this.documentTypeFormCtrl.patchValue(artifact.documentType);
        }
        this.cdr.detectChanges();
      })

    this.processId$
      .pipe(
        switchMap(pid => this.processSvc.myIamProcessPolicy(pid)),
        takeUntil(this.onDestroy)
      )
      .subscribe((policy) => {
        this.policy = policy;
        this.cdr.detectChanges();
      });

    this.itemLabels$ = combineLatest(this.labels$, itemLabels$)
      .pipe(
        takeUntil(this.onDestroy),
        map(([labels, itemLabel]) => {
          const itemLabels = Object.assign({}, itemLabel) as ItemLabels;
          if (!itemLabels) {
            this.itemLabelsIds$.next([]);

          } else {
            this.itemLabelsIds$.next(itemLabels.labelIds || []);

            if (labels && labels.length > 0 && itemLabels.labelIds) {
              itemLabels.labels = [];

              itemLabels.labelIds.forEach(labelId => {
                const labelFiltered = labels.filter(l => l.id === labelId);
                if (labelFiltered && labelFiltered.length > 0)
                  itemLabels.labels.push(labelFiltered[0]);
              });

              itemLabels.labels = itemLabels.labels.map(label => {
                if (!label) return;
                const globalLabel = GLOBAL_LABELS.find(_label => _label.title === label.title);
                if (globalLabel) {
                  label.isSVGIcon = globalLabel.isSVGIcon;
                }
                return label;
              });
              itemLabels.labelIds = itemLabels.labels.map(label => label.id);
              this.itemLabelsIds$.next(itemLabels.labelIds);
            }
          }
          return itemLabels;
        }));

    this.itemLabelsIds$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(ids => {
        this.itemLabelsIds = ids || [];
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.itemLabelsIds$.complete();
    this.artifactId$.complete();
    this.processId$.complete();
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  addLabel(label) {
    if (!label.label.id) {
      this.labelsSvc
        .create(label.label)
        .pipe(first())
        .subscribe(
          (_label: Label) => {
            this.store.dispatch(new LabelActions.CreateSuccess(_label));
            this.store.dispatch(
              new ItemLabelsActions.Create(
                this._artifact.id,
                _label.id,
                ItemType.DOCUMENT_ITEM,
                this._processId
              )
            );
            // this._store.dispatch(new KanbanItemActions.AddLabel(this.viewDocumentId, label.id));
          },
          catchError((err) => {
            console.error(err);
            this.store.dispatch(new LabelActions.CreateFail(err));
            return of(err);
          })
        );
      return;
    } else if (label.event.checked && label.label.id) {
      this.store.dispatch(
        new ItemLabelsActions.Create(
          this._artifact.id,
          label.label.id,
          ItemType.DOCUMENT_ITEM,
          this._processId
        )
      );
      return;
    } else {
      this.removeLabel(label.label);
    }
  }

  removeLabel(label) {
    this.store.dispatch(new ItemLabelsActions.Remove(this._artifact.id, label.id));
  }

  /**
   * Updates the artifact's description and marks the control pristine afterwards.
   * @param artifactId
   */
  public updateDescription(artifactId: string): void {
    const processId = this._artifact.processId;
    if (!processId || !this.policy) {
      return;
    }

    if (!this.policy.canUpdateArtifact) {
      return;
    }

    this.artifactSvc.update(processId, artifactId, 'description', this.descriptionFormCtrl.value)
      .pipe(first())
      .subscribe(artifact => {
        this.store.dispatch(new ProcessArtifactActions.UpdateOneSuccess(artifact))
        this.notifyService.success('PROJECT_ROOM_SETTINGS.CHANGES_APPLIED');
        this.descriptionFormCtrl.markAsPristine();
        this.cdr.detectChanges();

      }, err => {
        this.notifyService.error('PROJECT_ROOM_SETTINGS.CHANGES_FAILED');
        console.error(err);
      })
  }

  public updateAttribute(artifactId: string, attribute: ArtifactProperty, value: any): void {
    const processId = this._processId;
    if (!processId || !this.policy) {
      return;
    }

    if (attribute === 'keywords') {
      return;
    }

    if (attribute === 'description' && !this.policy.canUpdateArtifact) {
      return;
    }

    if ((attribute === 'month' || attribute === 'year') && !this.policy.canChangeAssessmentPeriod) {
      return;
    }

    if (this._artifact && this._artifact[attribute] === value) {
      return;
    }

    this.artifactSvc.update(processId, artifactId, attribute, value)
      .pipe(first())
      .subscribe(artifact => {
        this.store.dispatch(new ProcessArtifactActions.UpdateOneSuccess(artifact))
        this.notifyService.success('PROJECT_ROOM_SETTINGS.CHANGES_APPLIED');

      }, err => {
        this.notifyService.error('PROJECT_ROOM_SETTINGS.CHANGES_FAILED');
        console.error(err);
      })
  }

  public editArtifactName(artifact: ProcessArtifact, event): void {
    const processId = this._processId;
    if (processId && event && artifact && event !== artifact.title) {
      this.store.dispatch(new ProcessArtifactActions.Rename(processId, artifact.id, event));
    }
  }
}
