import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarService } from 'app/shared/modules/user-account/components/avatar/avatar.service';
import { PersonSelectorAutocompleteComponent } from './components/person-selector-autocomplete/person-selector-autocomplete.component';
import { PersonSelectedDropdownComponent } from './components/person-selected-dropdown/person-selected-dropdown.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { UserAccountModule } from 'app/shared/modules/user-account/user-account.module';
import { ApiModule } from 'app/shared/modules/api/api.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TooltipModule } from 'app/five-f/tooltip/tooltip.module';
import { SelectorAutocompleteComponent } from './components/selector-autocomplete/selector-autocomplete.component';
import {ScrollingModule} from "@angular/cdk-experimental/scrolling";
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
    imports: [
        ApiModule,
        OverlayModule,
        CommonModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        UserAccountModule,
        MatCheckboxModule,
        TooltipModule,
        ScrollingModule,
        MatTooltipModule
    ],
    declarations: [
        PersonSelectorAutocompleteComponent,
        SelectorAutocompleteComponent,
        PersonSelectedDropdownComponent
    ],
    providers: [
        AvatarService
    ],
    exports: [
        PersonSelectorAutocompleteComponent,
        SelectorAutocompleteComponent,
        PersonSelectedDropdownComponent
    ]
})
export class PersonSelectorAutocompleteModule {
}
