import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AuthGuard} from './shared/modules/api/services/auth-guard.service';
import {PageNotFoundComponent} from './shared/components/page-not-found/page-not-found.component';
import {VersionComponent} from './shared/modules/version/version.component'; // tslint:disable-line
import {SystemMaintenanceComponent} from './shared/components/system-maintenance/system-maintenance.component';
import {CavBrowserSupportComponent} from './modules/browser-support/containers/cav-browser-support/cav-browser-support.component';
import {BlankComponent} from './shared/components/blank/blank.component';
import {OauthCallbackComponent} from './modules/oauth/components/oauth-callback/oauth-callback.component';
import {MainAppComponent} from './modules/main/containers/main-app/main-app.component';
import {MainSessionComponent} from './modules/main/containers/main-session/main-session.component';
import {MainExternalComponent} from './modules/main/containers/main-external/main-external.component';
import {
  SubareaVerifierRequestConfirmComponent
} from './modules/workflows/workflows/audit/kap/modules/cav/containers/subarea-verifier-verify/step4/subarea-verifier-request-confirm.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  // SSO callback of Azure.
  {
    path: 'oauth',
    component: MainSessionComponent,
    children: [{
      path: 'callback',
      component: OauthCallbackComponent
    }]
  },
  {
    path: 'session/sign-up',
    component: MainSessionComponent,
    loadChildren: () => import('./modules/registration/registration.module').then(m => m.RegistrationModule),
  },
  // External workflow routes including KAP (CAC/CAV) and 3rd Party confirmation.
  // must be loaded first, to not being overlapped by the workflow routes module below.
  {
    path: 'workflows',
    component: MainExternalComponent,
    children: [{
      path: 'audit/kap/verification/:token',
      component: CavBrowserSupportComponent,
    }, {
      path: 'audit/kap/_verification/:token',
      component: SubareaVerifierRequestConfirmComponent,
    }, {
      path: 'audit/jap-bestaetigung-dritte',
      loadChildren: () => import('./modules/third-party-confirmation/third-party-confirmation-external.module').then(m => m.ThirdPartyConfirmationExternalModule),
    }]
  },
  // External routes of Quickshare and external link.
  {
    path: 'access/external',
    component: MainExternalComponent,
    loadChildren: () => import('./modules/external-access/external-access-routes.module').then(m => m.ExternalAccessRoutesModule),
  },
  // Big file external INBOX routes.
  {
    path: 'briefkasten',
    component: MainExternalComponent,
    loadChildren: () => import('./modules/file-inbox/file-inbox.module').then(m => m.FileInboxModule)
  },
  // Auth routes for session management and password reset.
  {
    path: 'session',
    component: MainSessionComponent,
    loadChildren: () => import('./modules/auth/auth-routes.module').then(m => m.AuthRoutesModule),
  },
  // Main App with valid session.
  {
    path: '',
    component: MainAppComponent,
    children: [
      // E-Mail dispatcher route. Routes out of email to process/task.
      // TODO: Must keep query params.
      {
        path: 'emails',
        loadChildren: () => import('./modules/workflow-engine/modules/email-route-dispatcher/email-route-dispatcher.module').then(m => m.EmailRouteDispatcherModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'clients',
        loadChildren: () => import('./modules/client/client.module').then(m => m.ClientModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'cms',
        loadChildren: () => import('./modules/cms/cms.module').then(m => m.CmsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'partner_links',
        loadChildren: () => import('./modules/cms/modules/partner-link/modules/partner-link-mgmt/partner-link-mgmt.module').then(m => m.PartnerLinkMgmtModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'collecto',
        loadChildren: () => import('./modules/quickcollector-v2/quickcollector-v2.module').then(m => m.QuickCollectorV2Module),
        canActivate: [AuthGuard]
      },
      {
        path: 'documents',
        loadChildren: () => import('./modules/documents-listing/documents-listing-routing.module').then(m => m.DocumentsListingRoutingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'tasks',
        loadChildren: () => import('./modules/tasks/tasks-routing.module').then(m => m.TasksRoutingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'quickshare',
        loadChildren: () => import('./modules/quickshare/quickshare.module').then(m => m.QuickshareModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'projects',
        loadChildren: () => import('./modules/project/project.module').then(m => m.ProjectModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'user/onboarding',
        loadChildren: () => import('./modules/onboarding/onboarding.module').then(m => m.OnboardingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'messages',
        loadChildren: () => import('./modules/inbox/inbox.module').then(m => m.InboxModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'user/tfa',
        loadChildren: () => import('./modules/two-factor-auth/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard-v2/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'label-items',
        loadChildren: () => import('./modules/label-items/label-items.module').then(m => m.LabelItemsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'faq',
        loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'workflows/timelines',
        loadChildren: () => import('./modules/project-room/project-room.module').then(m => m.ProjectRoomModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'workflows',
        loadChildren: () => import('./modules/workflows/workflows.module').then(m => m.WorkflowsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'develop',
        loadChildren: () => import('./modules/development/development.module').then(m => m.DevelopmentModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'user-profile',
        loadChildren: () => import('./modules/user-profile/user-profile.module').then(m => m.UserProfileModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'organization',
        loadChildren: () => import('./modules/organization/organization.module').then(m => m.OrganizationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'expertiserepo',
        loadChildren: () => import('./modules/expertise-repo/expertise-repo.module').then(m => m.ExpertiseRepoModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'formengine',
        loadChildren: () => import('./modules/formengine/formengine.module').then(m => m.FormengineModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'expression-builder',
        loadChildren: () => import('./modules/expression-builder/expressionbuilder.module').then(m => m.ExpressionBuilderModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'version',
        component: VersionComponent,
        canActivate: [AuthGuard]
      },
      {
        path: '5fadmin',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'tenant',
        loadChildren: () => import('./modules/tenant/tenant.module').then(m => m.TenantModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'workflow/:id/permissions',
        loadChildren: () => import('./modules/permissions-table-v2/permissions-table-v2.module').then(m => m.PermissionsTableV2Module),
        canActivate: [AuthGuard]
      },
      {
        path: 'process_management',
        loadChildren: () => import('./modules/process-management/process-management.module').then(m => m.ProcessManagementModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'signature',
        loadChildren: () => import('./modules/document-signature/modules/document-signature-router/document-signature-router.module').then(m => m.DocumentSignatureRouterModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'templates-management',
        loadChildren: () => import('./modules/templates-management/templates-management-routing.module').then(m => m.TemplatesManagementRoutingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'system',
        loadChildren: () => import('./modules/system-page/system-page.module').then(m => m.SystemPageModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'project-room/new',
        loadChildren: () => import('./modules/project-room/modules/new-project-room/new-project-room.module').then(m => m.NewProjectRoomModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'preparation-of-confirmation/new',
        loadChildren: () => import('./modules/preparation-of-confirmation/preparation-of-confirmation.module').then(m => m.PreparationOfConfirmationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'fastdocs',
        loadChildren: () => import('./modules/fastdocs/fastdocs-routing.module').then(m => m.FastdocsRoutingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'digitalbar',
        loadChildren: () => import('./modules/digitalbar/digitalbar-routing.module').then(m => m.DigitalbarRoutingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'fivef-agent',
        loadChildren: () => import('./modules/fivef-agent/fivef-agent-routing.module').then(m => m.FivefAgentRoutingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'fivef-desktop',
        loadChildren: () => import('./modules/fivef-desktop/fivef-desktop-routing.module').then(m => m.FivefDesktopRoutingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'datev',
        loadChildren: () => import('./modules/datev/datev-routing.module').then(m => m.DatevRoutingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'datev-dms',
        loadChildren: () => import('./modules/datev-dms/datev-dms-routing.module').then(m => m.DatevDmsRoutingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'kanban-board',
        loadChildren: () => import('./modules/kanban-board/kanban-board.module').then(m => m.KanbanBoardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'workflows/participations',
        loadChildren: () => import('./modules/process-participation/modules/participation-overview/participation-overview-routing.module').then(m => m.ParticipationOverviewRoutingModule),
      },
      {
        path: 'workflows/settings',
        loadChildren: () => import('./modules/process-settings/modules/settings-overview/settings-overview-routing.module').then(m => m.SettingsOverviewRoutingModule),
      }
    ]
  },
  {
    path: 'access',
    component: MainSessionComponent,
    children: [{
      path: 'page_not_found',
      component: PageNotFoundComponent
    }]
  },
  // Maintenance page on server shutdown/API offline.
  {
    path: 'system',
    component: MainSessionComponent,
    children: [{
      path: 'maintenance',
      component: SystemMaintenanceComponent
    }]
  },
  {
    path: 'blank',
    component: BlankComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'blank-2',
    component: BlankComponent,
    canActivate: [AuthGuard]
  },
  // { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy}
    // { provide: APP_BASE_HREF, useValue: '/develop' }
  ]
})
export class AppRoutingModule {
}
