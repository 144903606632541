import { Observable } from 'rxjs/internal/Observable';

/**
 * Namespace for Identity and access management (IAM).
 * NOTE: The main source is always the backend, not the frontend implementation.
 *
 * Author: Andreas Baier <andreas.baier@5fsoftware.de>
 */
export namespace Iam {
  /**
   * The authorized base state later returned by the current user's policy object.
   */
  export interface IBasePolicy {
    id: string;
    type: string;
    canSeeThat?: boolean;
  }

  /**
   * User permissions as policy object.
   */
  export interface IUserPolicy extends IBasePolicy {
    canSeeThatByUserPerm?: boolean;
  }

  export type IAuthModules = 'dms';

  /**
   * User permissions as policy object.
   */
  export interface IProcessPolicy extends IUserPolicy {
    hasMemberPriviliges?: boolean;
    canCommentProcess?: boolean;
    canCloseProcess?: boolean;
    canDeleteProcess?: boolean;
    hasDeleteProcess?:boolean;
    canTrashProcess?: boolean;
    hasProcessTrashing?: boolean;
    canReopenProcess?: boolean;
    canChangeColor?: boolean;
    canUploadArtifact?: boolean;
    canUploadTemplate?: boolean;
    canUploadArtifactVersion?: boolean;
    canUpdateDmsSyncSettings?: boolean;
    canRenameArtifact?: boolean;
    canUpdateArtifactReference?: boolean;
    canDeleteArtifact?: boolean;
    canDeleteDmsDocument?: boolean;
    canDownloadArtifact?: boolean;
    canExportArtifactsAsZip?: boolean;
    canAuditValue?: boolean;
    canRemindExternalParticipant?: boolean;
    canAccessAuditTrail?: boolean;
    canAddParticipant?: boolean;
    canChangeProcessOwner?: boolean;
    canChangeClient?: boolean;
    canChangeDescription?: boolean;
    canChangeIdentifier?: boolean;
    canChangeDueDate?: boolean;
    canChangeNotification?: boolean;
    canChangeTitle?: boolean;
    canCreateExternalAccess?: boolean;
    canRemoveParticipant?: boolean;
    canReorderProjectStructure?: boolean;
    canCreateTask?: boolean;
    canCreateSubworkflows?: boolean;
    canCreateSignature?: boolean;
    canAlterExtendedSettings?: boolean;
    canMoveArtifact?: boolean;
    canUpdateLabels?: boolean;
    canImportCollectoTemplate?: boolean;
    canCreateProjectTemplate?: boolean;
    canDuplicateCollecto?: boolean;
    canModifyCollectoStructure?: boolean;
    canUpdateDatevDmsSettings?: boolean;
    canUpdateDmsAutoSync?: boolean;
    canAccessExportMarker?: boolean;
    canChangeAssessmentPeriod?: boolean;
    canUpdateArtifactKeywords?: boolean;
    canUpdateArtifact?: boolean;
    canDownloadReports?: boolean;
  }

  /**
   * Example base policy describing the default permissions without any context.
   */
  export const DEFAULT_POLICY: IBasePolicy = {
    id: 'base_default_policy',
    type: 'base_policy',
    canSeeThat: false,
  };

  /**
   * Default policy describing the default permissions of a user context.
   */
  export const DEFAULT_USER_POLICY: IUserPolicy = {
    id: 'user_default_policy',
    type: 'user_policy',
    canSeeThatByUserPerm: false,
  };

  /**
   * Default policy describing the default permissions of a user context.
   */
  export const DEFAULT_PROCESS_POLICY: IProcessPolicy = {
    id: 'process_policy',
    type: 'process_policy',
    hasMemberPriviliges: false,
    canCommentProcess: false,
    canCloseProcess: false,
    canDeleteProcess: false,
    hasDeleteProcess: false,
    canTrashProcess: false,
    hasProcessTrashing: false,
    canReopenProcess: false,
    canChangeColor: false,
    canUploadArtifact: false,
    canUploadArtifactVersion: false,
    canUpdateDmsSyncSettings: false,
    canRenameArtifact: false,
    canDeleteArtifact: false,
    canDeleteDmsDocument: false,
    canDownloadArtifact: false,
    canExportArtifactsAsZip: false,
    canUpdateArtifactReference: false,
    canRemindExternalParticipant: false,
    canAuditValue: false,
    canAccessAuditTrail: false,
    canAddParticipant: false,
    canChangeClient: false,
    canChangeDescription: false,
    canChangeIdentifier: false,
    canChangeDueDate: false,
    canChangeNotification: false,
    canChangeTitle: false,
    canCreateExternalAccess: false,
    canRemoveParticipant: false,
    canReorderProjectStructure: false,
    canChangeProcessOwner: false,
    canCreateTask: false,
    canCreateSubworkflows: false,
    canCreateSignature: false,
    canAlterExtendedSettings: false,
    canMoveArtifact: false,
    canUpdateLabels: false,
    canImportCollectoTemplate: false,
    canCreateProjectTemplate: false,
    canDuplicateCollecto: false,
    canModifyCollectoStructure: false,
    canUpdateDatevDmsSettings: false,
    canUpdateDmsAutoSync: false,
    canAccessExportMarker: false,
    canChangeAssessmentPeriod: false,
    canUpdateArtifactKeywords: false,
    canUpdateArtifact: false,
    canDownloadReports: false
  };

  export interface IPermissionManager {
    /**
     * Policy object as observable for listening on changes.
     * Each object state change is immutable.
     */
    policy$: Observable<IBasePolicy>;

    /**
     * Current policy snapshot based on current authorization knowledge.
     * The return value is an immutable (frozen object).
     */
    readonly currentPolicy: IBasePolicy;

    /**
     * Dispatches operations to fill policy with new authorization knowledge.
     */
    dispatch(newPolicy: IBasePolicy): void;

    /**
     * Loads and returns the policy.
     */
    load(newPolicy: IBasePolicy): Observable<IBasePolicy>;

    /**
     * Reset the permission manager to its default policy.
     */
    reset(): void;
  }

  /**
   * Base interface for an authorization context.
   */
  export interface IAuthorizationContext {
    getAuthorizationManager(parent: IPermissionManager): IPermissionManager;
  }
}
