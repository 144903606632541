<form class="five-f-mat-input">
  <mat-form-field>
    <input type="text" [placeholder]="elementName" aria-label="Number" matInput [formControl]="myControl"
           [matAutocomplete]="auto" (input)="onBlurEventHandler()">
    <button mat-button *ngIf="myControl.value" matSuffix mat-icon-button
            aria-label="Clear" (click)="reset(); $event.stopPropagation()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="contactSelected($event)" class="invitations--autocomplete">
      <cdk-virtual-scroll-viewport *ngIf="(filteredOptions | async)?.length > 0" style="height: 240px;" [itemSize]="25" autosize>
      <mat-option style="width: 100%;" *cdkVirtualFor="let option of filteredOptions | async" [value]="option">
        <p class="d-flex align-items-center my-1">
          <dvtx-avatar [size]="'xs'" [profile]="option" matTooltip="{{option?.email}}" [showTitle]="false" *ngIf="filterType !== 'organizationContact'"></dvtx-avatar>
          {{ ( option.name.length > 20 ) ? ( option.name | slice:0:20 ) + '..' : ( option.name ) }}
          <ng-container *ngIf="valueIsEmail && option.email && filterType === 'organizationContact'">
            ({{option.email}})
          </ng-container>
        </p>
      </mat-option>
      </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
  </mat-form-field>
</form>
