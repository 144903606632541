<p class="filter-selection-title d-flex align-items-center justify-content-between">
  {{title}}
  <span
    *ngIf="selectedOptions?.length"
    class="d-inline-flex align-items-center justify-items-center"
    [matBadge]="selectedOptions?.length"
    matBadgeOverlap="false"
    color="primary">
  </span>
</p>
<div class="list-group">
  <ng-container *ngFor="let option of filterdOptions$ | async">
    <button
        [matTooltip]="option?.firstName ? option?.firstName + ' ' + option?.lastName : option?.email"
        type="button"
        class="list-group-item list-group-item-action"
        [class.active]="isSelected(option)"
        (click)="changeSelection(option)">
        <dvtx-avatar [size]="'xs'" [profile]="avatarService.getProfile(option?.email)"></dvtx-avatar>
        <span class="text-truncate">{{ option?.firstName ? option?.firstName + ' ' + option?.lastName : option?.email }}</span>
      </button>
  </ng-container>
</div>
