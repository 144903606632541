import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class CollectorUiChangesService {

  showImportPanel$ = new BehaviorSubject(false);
  showSearchPanel$ = new BehaviorSubject(false);

  constructor() {
  }

  toggleImportPanel() {
    const current = this.showImportPanel$.value;
    this.showImportPanel$.next(!current);
  }

  showImportPanel() {
    this.showImportPanel$.next(true);
  }

  hideImportPanel() {
    this.showImportPanel$.next(false);
  }

  toggleSearchPanel() {
    const current = this.showSearchPanel$.value;
    this.showSearchPanel$.next(!current);
  }

  showSearchPanel() {
    this.showSearchPanel$.next(true);
  }

  hideSearchPanel() {
    this.showSearchPanel$.next(false);
  }
}
