<div class="dvtx-title-editable d-flex align-items-center {{classNames}}">
  <!-- NOTE: mousedown and click event propagation prevention is necessary to
       make content editable usable with CDK drag 'n drop component which consume these
       event when blubbling up -->
  <span
    #title
    (click)="editDocName($event)"
    (mousedown)="$event.stopPropagation()"
    [class]="fontSize"
    [class.mr-1]="editIcon"
    [class.editable]="editable && !disabled"
    [class.five-f-content-editable-container]="!editable && !disabled"
    [attr.contenteditable]="editable && !disabled"
    [attr.data-ph]="placeholder | translate"
    (keydown.enter)="onReturn($event, title)"
    (keydown.esc)="cancelChanges()"
    (focusout)="onReturn($event, title)"
    (blur)="onReturn($event, title)"
    [class.text-truncate]="truncateTitle && !editable">
  </span>
  <span *ngIf="percentage"> %</span>
  <mat-icon class="edit--icon-button-{{showOnHover}} {{iconStyleClass}}"
            *ngIf="!disabled && editIcon && !editable" (click)="enableEditing()">edit</mat-icon>
  <mat-icon *ngIf="!disabled && removeIcon && !editable" class="remove--icon-button-{{showOnHover}}"
            (click)="remove()">close</mat-icon>
</div>
