import {first, startWith} from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Subject} from 'rxjs';
import { EmailValidator } from 'app/lib/validator/email_valid';
import { NotificationService } from 'app/shared/modules/notification/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { validPhoneNumber } from 'app/modules/quickshare/modules/new-quickshare/components/recipient-selection/recipient-selection.component';

/**
 * Provides a HTML span contenteditible.
 */
@Component({
  selector: 'dvtx-title-editable',
  templateUrl: './title-editable.component.html',
  styleUrls: ['./title-editable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitleEditableComponent implements OnInit, OnDestroy {
  private refreshSubject$: Subject<any> = new Subject();

  @ViewChild('title', { static: true }) title: ElementRef;

  /**
   * Truncates the title instead of showing it in multiline setup.
   */
  @Input()
  public truncateTitle = false;

  @Input() percentage = false;
  @Input() decimal = false;
  @Input() integer = false;
  @Input() isNew = false;
  @Input() placeholder = 'FORM.SET_TITLE';
  @Input() fontSize = 'h1';
  @Input() editable = false;
  @Input() editIcon = false;
  @Input() saveIcon = false;
  @Input() cancelIcon = false;
  @Input() allowNull = false;
  @Input() classNames = '';

  // iconStyleClass == five-f-mat-icon-button enables mat-icon-button styling.
  // gray color, action blue on hover.
  // Leaving it empty ('') keeps the Collecto line item styling with opacity 0.3.
  // So edit icon style is lighter/in the background.
  @Input() iconStyleClass: '' | 'five-f-mat-icon-button' = '';
  @Input() miltilines = false;
  @Input() disabled = false;
  @Input() renameDoc = false;
  @Input() showOnHover = false;
  @Input() removeIcon = false;
  @Input() isEmail = false;
  @Input() isPhoneNumber = false;
  @Input() maxValue;

  _value: string = '';
  @Input() set value(val: string) {
    if ((val != undefined || val != null) && this._value !== val) {
      this._value = val;
      this.title.nativeElement.innerText = val;
    }
  }

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>(null);
  @Output() onEditing: EventEmitter<any> = new EventEmitter<any>(null);
  @Output() onCancelEditing: EventEmitter<any> = new EventEmitter<any>(null);

  get value(): string {
    return this._value;
  }

  constructor(public _cdr: ChangeDetectorRef,
    private _notifyService: NotificationService,
    private _translateSvc: TranslateService) {
    this.refreshSubject$.pipe(startWith(undefined))
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.refreshSubject$.complete();
  }

  onReturn($event, content) {
    if (this.miltilines) {
      return true;
    }
    if ($event) $event.stopPropagation();
    this.save(content);
    return false;
  }

  onBlur($event, content) {
    $event.stopPropagation();
    this.save(content);
    return false;
  }

  save(content) {
    let value = content.innerText;
    if (!this.allowNull && !value || value === this.value) {
      this.cancelChanges();
      return false;
    }
    if (this.isEmail && !EmailValidator.emailValid(value)) {
      this.cancelChanges();
      this._notifyService.error('ORGANIZATION.CREATION.INVALID_EMAIL')
      return false;
    }
    if (this.isPhoneNumber && !validPhoneNumber(value)) {
      this.cancelChanges();
      this._notifyService.error('QUICKSHARE.INVALID_PHONE_NUMBER')
      return false;
    }
    if (this.renameDoc) {
      const parts = value.split('.');
      const onlyWhitespace = parts[0].replace(/\s/g, '');

      if (!onlyWhitespace) {
        this.cancelChanges();
        return false;
      }
    }
    if(this.decimal) {
      const number = Number(value);
      if (typeof number != 'number' || isNaN(number)) {
        this.cancelChanges();
        return false;
      }
      value = number.toFixed(2);
    }
    if(this.integer) {
      const number = Number(value);
      if (typeof number != 'number' || !Number.isInteger(number) || isNaN(number) || (this.maxValue && number > this.maxValue)) {
        this.cancelChanges();
        return false;
      }
    }
    this.onChange.emit(value);
    this._value = value;
    this.refreshSubject$.next(this._value);
    if (this.isNew) {
      this._value = '';
      this.title.nativeElement.innerText = this.value;
      return;
    }
    this._value = value
    this.title.nativeElement.innerText = this.value;

    if (this.editIcon || this.renameDoc) {
      this.editable = false;
    }
    this._cdr.detectChanges();
  }

  editDocName($event) {
    // NOTE: mousedown and click event propagation prevention is necessary to
    // make content editable usable with CDK drag 'n drop component which consume these
    // event when blubbling up -->
    $event.stopPropagation();
    if (this.renameDoc) {
      this.enableEditing()
    }
  }

  enableEditing() {
    if (this.disabled) {
      return;
    }
    if (this.renameDoc && this.editable) {
      return;
    }
    this.onEditing.emit();
    this.editable = true;
    this.title.nativeElement.focus();
    this._cdr.detectChanges();
    this.setEndOfContenteditable(this.title.nativeElement);
  }

  private setEndOfContenteditable(contentEditableElement) {
      let range, selection;
      if (document.createRange) { // Firefox, Chrome, Opera, Safari, IE 9+
        range = document.createRange(); // Create a range (a range is a like the selection but invisible)
        range.selectNodeContents(contentEditableElement); // Select the entire contents of the element with the range
        range.collapse(this.renameDoc ? true : false); // collapse the range to the end point. false means collapse to end rather than the start
        selection = window.getSelection(); // get the selection object (allows you to change selection)
        selection.removeAllRanges(); // remove any selections already made
        selection.addRange(range); // make the range you have just created the visible selection
      }
  }

  cancelChanges() {
    this.title.nativeElement.innerText = this.value;
    if (!this.isNew && (this.editIcon || this.renameDoc)) {
      this.editable = false;
    }
    this.onCancelEditing.emit();
    this._cdr.detectChanges();
    return false;
  }

  remove() {
    this.onChange.emit('');
  }
}
