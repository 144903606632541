import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AppState } from '../../../../reducers';
import { Store } from '@ngrx/store';
import { NaturalPersonSelectors, UserAvatarActions, UserAvatarSelectors } from '../../../../+store';
import { NaturalPerson } from '../../../../models/natural-person.model';
import { UpdateMy } from '../../../../+store/natural-person/natural-person.actions';
import { Portal, TemplatePortal } from '@angular/cdk/portal';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SplitViewDialogComponent } from 'app/shared/modules/page-container/split-view-dialog/split-view-dialog.component';
import { Profile } from 'app/shared/modules/user-account/models/profile';
import { AvatarService } from 'app/shared/modules/user-account/components/avatar/avatar.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { UserAvatar } from 'app/+store/user-avatar/user-avatar';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ClientRoute } from 'app/shared/modules/page-container/tab-nav-bar/tab-nav-bar.component';

@Component({
  selector: 'dvtx-profile-container',
  templateUrl: './profile-container.component.html',
  styleUrls: ['./profile-container.component.scss']
})
export class ProfileContainerComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();
  public naturalPerson: { contactPerson: NaturalPerson, isValid: boolean };
  public completePerson: NaturalPerson;
  public edit = false;
  private step: number = 0;
  // PROFILE IMAGE CHANGE
  @ViewChild('buttonsToolbar', { static: true }) buttonsToolbar: TemplateRef<any>;
  @ViewChild('context', { static: true }) context: TemplateRef<any>;
  private buttonsToolbarPortal: Portal<any>;
  private contextPortal: Portal<any>;
  private dialogRef: MatDialogRef<SplitViewDialogComponent>;
  public myAvatarObject: Profile = <Profile>{};
  public profileImageChangedEvent: any = '';
  private croppedProfileImage: string = '';
  public uploadProfileButton: HTMLElement;
  public uploadError;
  private image$: Observable<UserAvatar>;
  public avatar: UserAvatar;
  public routes: ClientRoute[];
  public activeLink: string;
  private latestNaturalPerson: { contactPerson: NaturalPerson; isValid: boolean; };

  constructor(
    private _fb: UntypedFormBuilder,
    private router: Router,
    private _store: Store<AppState>,
    private _dialog: MatDialog,
    private _viewContainerRef: ViewContainerRef,
    private avatarService: AvatarService,
    private _cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.routes = [{
      title: 'USER_NAVIGATON.PROFILE',
      route: `/user-profile/user/profile`
    }];
    this.activeLink = this.routes[0].title;
    this._store.select(NaturalPersonSelectors.getUsersNaturalPerson).pipe(filter(Boolean), takeUntil(this.onDestroy)).subscribe((my: NaturalPerson) => {
      this.latestNaturalPerson = { contactPerson: my, isValid: true };
      this.naturalPerson = JSON.parse(JSON.stringify({ contactPerson: my, isValid: true }));
      this.completePerson = my;
      if (my.mainEmailAddress && my.mainEmailAddress.emailAddress) {
        this.refreshAvatar();
      }
      this._cdr.detectChanges();
    });
    this._cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private refreshAvatar() {
    this.myAvatarObject = this.avatarService.getProfile(this.completePerson.mainEmailAddress.emailAddress, this.completePerson);
    this.image$ = this._store.select(UserAvatarSelectors.getOne(this.completePerson.mainEmailAddress.emailAddress))
    this.image$.pipe(filter(Boolean), takeUntil(this.onDestroy)).subscribe((avatar: UserAvatar) => {
      if (avatar && avatar.avatar) {
        this.avatar = avatar.avatar;
      }
      this._cdr.detectChanges();
    });
  }

  private setStep(index: number) {
    this.step = index;
  }

  public save() {
    this._store.dispatch(new UpdateMy({
      ...this.completePerson,
      ...this.naturalPerson.contactPerson,
      mainEmailAddress: this.completePerson.mainEmailAddress
    }));

    this.edit = false;
    this._cdr.detectChanges();
  }

  private _initContextPortal() {
    if (this.contextPortal) {
      return;
    }
    this.contextPortal = new TemplatePortal(
      this.context,
      this._viewContainerRef
    );
  }

  public profileImageChangeRequest() {
    this.uploadProfileButton = document.getElementById('uploadProfileButton') as HTMLElement;
    this.uploadProfileButton.click();
  }

  public profileImageChangeEvent(event) {
    this.uploadError = false;
    this.profileImageChangedEvent = event;
    if (event.srcElement.files[0] && event.srcElement.files[0].size > (1048576 / 2)) {
      this.uploadError = true;
      return;
    }
    this._initContextPortal();

    this.buttonsToolbarPortal = new TemplatePortal(
      this.buttonsToolbar,
      this._viewContainerRef
    );


    this.dialogRef = this._dialog.open(SplitViewDialogComponent, {
      data: {
        color: '#233246',
        icon: null,
        context: this.contextPortal,
        buttonsToolbar: this.buttonsToolbarPortal
      }
    });
  }

  public profileImageCropEvent(event: ImageCroppedEvent) {
    this.croppedProfileImage = event.base64;
  }

  public profileImageLoaded() {
    console.log('Image loaded');
  }

  public profileImageLoadedFailed() {
    console.error('Image loading failed');
  }

  public onSubmitCroppingClick(): void {
    this.myAvatarObject.image = this.croppedProfileImage;
    const userAvatar = new UserAvatar(this.myAvatarObject.email, this.myAvatarObject.image, this.completePerson.firstName, this.completePerson.lastName, null, this.completePerson.title);
    this._store.dispatch(new UserAvatarActions.Updated(userAvatar));

    this.dialogRef.close();
  }

  public profileImageRemove(): void {
    this.myAvatarObject.image = null;
    const userAvatar = new UserAvatar(this.myAvatarObject.email, this.myAvatarObject.image, this.completePerson.firstName, this.completePerson.lastName, null, this.completePerson.title);
    this._store.dispatch(new UserAvatarActions.Updated(userAvatar));
    this.refreshAvatar();
  }

  public resetChanges() {
    this.naturalPerson = null;
    setTimeout(() => {
      this.naturalPerson = JSON.parse(JSON.stringify(this.latestNaturalPerson));
    }, 400);
    this._cdr.detectChanges();
  }
}
