<div class="mat-dialog-inner-wrap" [style.borderColor]="color">
  <div class="mat-dialog-header">
    <h4 mat-dialog-title>{{data.title | translate}}</h4>
  </div>
  <div mat-dialog-content>
    <h3 class="mb-2">{{data.message | translate}}</h3>

    <dvtx-avatar mat-list-icon [size]="'xs'" [profile]="avatarService.getProfile(person.email, person)" [showTitle]='true'></dvtx-avatar>
    <div class="customEmailText">{{person?.email}}</div>

    <div class="mt-2 d-flex align-items-center" *ngIf="data.adminOption">
      <mat-checkbox #adminCheckbox (change)="adminChanged($event)" [checked]="false">
        <span [innerHTML]="'ROLE.PR_ADMIN' | translate"></span>
      </mat-checkbox>

      <div class="align-self-center">
        <button mat-icon-button
                color="gray"
                matTooltip="{{ 'PROJECT_ROOM.PROJECT_ROOM_ADMIN_INFO' | translate }}">
          <mat-icon>info</mat-icon>
        </button>
      </div>
    </div>

    <div class="mb-2 d-flex align-items-center" *ngIf="data.recursiveOption">
      <mat-checkbox #recursiveCheckbox (change)="recursiveChanged($event)" [checked]="true">
        <span [innerHTML]="'WORKFLOW_ENGINE.APPLY_TO_SUBWORKFLOWS' | translate"></span>
      </mat-checkbox>

      <div class="align-self-center">
        <button mat-icon-button
                color="gray"
                matTooltip="{{ 'PROJECT_ROOM.APPLY_TO_SUBWORKFLOWS' | translate }}">
          <mat-icon>info</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="remove" mat-dialog-actions class="d-flex justify-content-end">
    <button mat-button (click)="onNoClick()">{{ cancelButtonTitle | translate }}</button>
    <button mat-raised-button color="warn" (click)="onSubmitClick()">{{ submitButtonTitle | translate }}</button>
  </div>
  <div *ngIf="!remove" mat-dialog-actions class="d-flex justify-content-end">
    <button mat-button color="warn" (click)="onNoClick()">{{ cancelButtonTitle | translate }}</button>
    <button mat-raised-button color="primary" (click)="onSubmitClick()">{{ submitButtonTitle | translate }}</button>
  </div>
</div>
