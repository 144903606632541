import {Injectable, ComponentFactoryResolver, Injector, OnDestroy} from '@angular/core';
import {AvatarComponent} from './avatar.component';
import {Profile} from 'app/shared/modules/user-account/models/profile';
import {Subscription, Subject} from 'rxjs';
import {ContactSelectors, EmailToNameSelectors} from 'app/+store';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {takeUntil, filter, startWith} from 'rxjs/operators';
import {StringUtils} from 'app/lib/string_utils';
import {AccountStatus} from 'app/+store/contact/contact.selectors';
import {TranslateService} from '@ngx-translate/core';
import {SimpleAvatarComponent} from '../simple-avatar/simple-avatar.component';
import {UserAvatar} from 'app/+store/user-avatar/user-avatar';

@Injectable()
export class AvatarService implements OnDestroy {
  onDestroy: Subject<void> = new Subject<void>();
  organizationMembersSubscribtion$: Subscription;
  organizationMembers$;
  contactSubscription: Subscription;
  emailToNameSubscription: Subscription;
  accountStatus = AccountStatus;
  systemAccount = false;
  lang = 'de';

  constructor(private resolver: ComponentFactoryResolver,
              private injector: Injector,
              private _i18nSvc: TranslateService,
              private store: Store<AppState>) {
    if (this.organizationMembersSubscribtion$) {
      this.organizationMembersSubscribtion$.unsubscribe();
    }

    this.organizationMembersSubscribtion$ = this.store.select(ContactSelectors.getMembersAndContactPersonsOfSelectedOrg()).pipe(takeUntil(this.onDestroy)).subscribe(members => {
      this.organizationMembers$ = members;
    });

    this._i18nSvc.onLangChange.pipe(startWith({lang: this._i18nSvc.currentLang}),
      takeUntil(this.onDestroy)).subscribe(lang => this.lang = lang.lang)
  }

  public get organizationMembers() {
    return this.organizationMembers$;
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  /**
   * Get an Avatar in native HTML content.
   *
   * @param {Profile} profile The user profile object.
   * @param {string} size of the avatar.
   * @param {Function} [options] callbackFn function for clicking on the avatar.
   * @param {boolean} [optional] showTitle show user name next to the avatar
   *
   *
   * @memberOf AvatarService
   */
  get(profile: Profile, size: string, callbackFn?: any, showTitle?: boolean) {
    const factory = this.resolver.resolveComponentFactory(AvatarComponent);
    const componentRef = factory.create(this.injector);
    componentRef.instance.profile = this.getProfile(profile.email, profile);
    componentRef.instance.size = size;
    componentRef.instance.callbackFn = callbackFn;
    componentRef.instance.showTitle = showTitle;
    componentRef.hostView.detectChanges();
    const {nativeElement} = componentRef.location;
    return componentRef.location.nativeElement;
  }

  getWithImage(profile: Profile, size: string, userAvatar: UserAvatar) {
    const factory = this.resolver.resolveComponentFactory(SimpleAvatarComponent);
    const componentRef = factory.create(this.injector);
    componentRef.instance.profile = this.getProfile(profile.email, profile);
    componentRef.instance.size = size;
    componentRef.instance.userAvatar = userAvatar;
    componentRef.hostView.detectChanges();
    const {nativeElement} = componentRef.location;
    return componentRef.location.nativeElement;
  }

  getProfile(email, account = null) {
    let emailtToName = null;
    const contact = this.organizationMembers$.find(c => StringUtils.simpleCompare(c.email, email));
    if (contact && account && account.firstName == null) {
        account.firstName = contact.firstName;
        account.lastName = contact.lastName;
        account.name = contact.name;
    }
    if (this._isSystemEmail(email)) {
      return {
        email: email,
        name: this.lang === 'de' ? 'Systemnachricht' : 'System Message',
        system: true
      }
    }

    if (contact) {
      if (account && typeof (account) === 'object') {
        const acc = Object.assign({}, account);
        acc.isMember = contact.isMember;
        acc.isVerified = contact.isVerified;
        acc.name = contact.name ? contact.firstName + ' ' + contact.lastName : email;
        acc.firstName = contact.firstName;
        acc.lastName = contact.lastName;
        acc.hasAccount = contact.hasAccount;
        acc.hasRegisteredAccount = contact.hasRegisteredAccount;
        return acc;
      }
      const _contact = Object.assign({}, contact);
      _contact.name = contact.name ? contact.firstName + ' ' + contact.lastName : email;
      return _contact;
    }

    if (this.emailToNameSubscription) {
      this.emailToNameSubscription.unsubscribe();
    }
    this.emailToNameSubscription = this.store.select(
      EmailToNameSelectors.getByEmail(email)
    ).pipe(
      takeUntil(this.onDestroy)
    ).subscribe(
      (x) => {
        if (x && account && typeof (account) === 'object') {
          account.firstName = x.firstName;
          account.lastName = x.lastName;
          account.name = x.firstName + ' ' + x.lastName;
        } else if (x) {
          emailtToName = new Object();
          emailtToName.email = email;
          emailtToName.firstName = x.firstName;
          emailtToName.lastName = x.lastName;
          emailtToName.name = x.firstName + ' ' + x.lastName;
        }
      }
    );


    if (this.contactSubscription) {
      this.contactSubscription.unsubscribe();
    }
    this.contactSubscription = this.store.select(
      ContactSelectors.getAccountStatusOfSelectedOrgByEmail(email)
    ).pipe(
      takeUntil(this.onDestroy)
    ).subscribe(
      (x) => {
        if (account && typeof (account) === 'object') {
          account.name = account.name ? account.firstName + ' ' + account.lastName : email;
          account.isMember = x === this.accountStatus.Member;
          account.isVerified = x === this.accountStatus.VerifiedUser;
          account.hasRegisteredAccount = contact && contact.hasRegisteredAccount;
        }
      }
    );

    if (account) {
      return account;
    } else if (emailtToName) {
      return emailtToName;
    } else {
      return {
        email: email,
        name: email
      }
    }
  }

  /**
   * Adds firstName and lastName property on a given profile
   * by splitting the name attribute.
   *
   * @param name
   * @param accountProfile
   */
  updateAccountProfileFromName(name, accountProfile) {
    if (name && typeof(name) === 'string') {
      const profileName = name.split(' ');
      if (profileName.length > 1) {
        accountProfile['firstName'] = profileName[0];
        accountProfile['lastName'] = profileName.slice(1).join(' ').trim();
      }
    }
  }

  private _isSystemEmail(email: string) {
    if (!email) return false;

    switch (email) {
      case 'system@5fsoftware.de':
      case 'support@5fsoftware.de':
      case 'quickshare@5fsoftware.de':
      case 'third-party@5fsoftware.de':
      case 'bookman@5fsoftware.de':
      case 'signature@5fsoftware.de':
      case 'fastdocs@5fsoftware.de':
      case 'service@fastdocs.de':
        this.systemAccount = true;
        return true;
      default:
        return false;
    }
  }

}
