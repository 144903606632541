import {Process} from "app/+store/process/process";
import {IApiResourceBuilder} from "app/shared/modules/api-resource/models/api.interface";
import {CollectorCategory} from "../../../../../../+store/collector-category/collector-category";
import { SettingsAccessParameter } from "app/+store/process-management/process-management";
import { IColumnSetup } from "app/modules/quickcollector-v2/components/items-listing/items-listing.repository";

export class WorkflowNode {

  constructor(
    public id: string,
    public title: string,
    public description: string,
    public color: string,
    public icon: string,
    public isSvgIcon: boolean,
    public type: string,
    public profile: string,
    public parentId: string,
    public children: WorkflowNode[] = [],
    public categories: CollectorCategory[] = [],
    public subStorage?: string,
    public permitCommenting?: SettingsAccessParameter,
    public permitUploading?: SettingsAccessParameter,
    public columnSetup?: IColumnSetup[]
  ) {
  }

  static toObject(node: WorkflowNode): object {
    return {
      id: node.id,
      title: node.title,
      description: node.description,
      color: node.color,
      type: node.type,
      profile: node.profile,
      icon: node.icon,
      isSvgIcon: node.isSvgIcon,
      parentId: node.parentId,
      children: node.children?.map(c => WorkflowNode.toObject(c)),
      categories: node.categories,
      subStorage: node.subStorage,
      permitCommenting: node.permitCommenting,
      permitUploading: node.permitUploading,
      columnSetup: node.columnSetup
    }
  }

  static toJson(node: WorkflowNode | object): object {
    return {
      title: node['title'],
      description: node['description'],
      color: node['color'],
      type: node['type'],
      profile: node['profile'],
      dms_folder_id: node['dmsFolderId'],
      sub_storage: node['subStorage'],
      parentId: node['parentId'],
      children: node['children']?.map(c => WorkflowNode.toJson(c)),
      categories: node['categories'],
      subStorage: node['subStorage'],
      permitCommenting: node['permitCommenting'],
      permitUploading: node['permitUploading'],
      columnSetup: node['columnSetup']
    }
  }

  static toApiJson(nodes: object[]): object[] {
    return nodes?.map(node => WorkflowNode.toJson(node))
  }



  static fromJson(obj: object): WorkflowNode {
    return new WorkflowNode(
      obj['id'],
      obj['title'],
      obj['description'],
      obj['color'],
      obj['icon'],
      obj['isSvgIcon'],
      obj['type'],
      obj['profile'],
      obj['parentId'],
      obj['children']?.map(c => WorkflowNode.fromJson(c)),
      obj['categories'],
      obj['subStorage'],
      obj['permitCommenting'],
      obj['permitUploading'],
      obj['columnSetup']
    )
  }

  static generateTree(nodes: WorkflowNode[]): WorkflowNode {
    const root: WorkflowNode = nodes[0];
    const workflowNodeIndex = {};
    const nodeCatalog: WorkflowNode[] = [];

    nodes.forEach((workflowNode: WorkflowNode) => {
      workflowNodeIndex[workflowNode.id] = workflowNode;
      nodeCatalog.push(workflowNode);
    });

    nodeCatalog.forEach((node) => {
      workflowNodeIndex[node.parentId]?.children.push(node);
    });
    return workflowNodeIndex[root.id];
  }

  static flattenTree(node: WorkflowNode): WorkflowNode[] {
    let root = Object.assign({}, node);
    root.children = []
    let nodes = [root];
    return nodes.concat(...node.children.map(n => WorkflowNode.flattenTree(n)));
  }

  static mapIntoWorkflowNodes(processes: Process[]): WorkflowNode[] {
    return processes.map(p => {
      return new WorkflowNode(
        p.id,
        p.title,
        p.description,
        p.color,
        p.icon,
        p.isSvgIcon,
        p.processType,
        p.profile,
        p.parentId,
        [],
        p.categories,
        null
      );
    })
  }
}

export class WorkflowNodeBuilder implements IApiResourceBuilder<WorkflowNode> {


  fromResponse(data): WorkflowNode {
    const projectStructure = data.attributes.process_structure;
    return this.fromJson(projectStructure);
  }

  toRequest(_: WorkflowNode) {
    return null;
  }

  fromJson(obj: object): WorkflowNode {
    return new WorkflowNode(
      obj['id'],
      obj['title'],
      obj['description'],
      obj['color'],
      obj['icon'],
      obj['svg_icon'],
      obj['process_type'],
      obj['process_profile'],
      obj['parent_id'],
      obj['children']?.map(c => this.fromJson(c)),
      obj['categories']?.map(c => this.collectoCategoriesToApiJson(c)),
      obj['permit_comments'],
      obj['permit_upload'],
      obj['column_setup']
    )
  }

  collectoCategoriesToApiJson(category: object): object {
    return {
      id: category['id'],
      title: category['title'],
      color: category['color'],
      items: category['items']?.map(item => ({
        id: item['id'],
        title: item['title'],
        category_id: item['category_id'],
        priority: item['priority'],
        description: item['description'],
        due_date: item['due_date'],
        document_type: item['document_type'],
      }))
    }
  }
}
