import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {Subject} from 'rxjs/internal/Subject';
import {
  FiveFStatusesMap,
  FiveFStatusToApiMap,
  Process,
  ProcessListingItemV3,
  ProcessListingV3Participant,
  ProcessListingV3Statistics
} from 'app/+store/process/process';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {OrganizationSelectors} from 'app/+store/organization';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  first,
  map,
  take,
  takeUntil
} from 'rxjs/operators';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Net} from 'app/lib/net/uuid';
import {Portal, TemplatePortal} from '@angular/cdk/portal';
import {Client} from 'app/+store/client/client';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {WORKFLOWS_TYPES, WORKFLOWS_TYPES_TITLES} from 'app/five-f/workflow-type-selector/workflow-type-selector.config';
import {Feature} from 'app/+store/feature/feature';
import {FeatureSelectors, ProcessActions, ProjectStatisticsActions} from 'app/+store';
import {FiltersContainerComponent} from 'app/five-f/filters/container/filters-container.component';
import {ProcessListingV3Service} from 'app/+store/process/process-listing-v3.service';
import {IProcessV3Query} from 'app/+store/process/process.interface';
import {MatDialog} from '@angular/material/dialog';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {ProcessService} from 'app/+store/process/process.service';
import {FavoriteActions} from 'app/+store/favorite';
import {Organization} from 'app/models/organization.model';
import {IResource} from 'app/shared/modules/api/models/resource.interface';
import {ClientService} from 'app/+store/client/client.service';
import {IProcessListingColumns, IProcessListingItem} from 'app/five-f/process-listing/models/process-listing.interface';
import {ITabNavRoute} from '../../organization-card/models/tab-nav-route.interface';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class AbstractUserProcessListing implements AfterViewInit, OnInit, OnDestroy {
  protected onDestroy = new Subject<void>();

  _parentId: string;
  allClients: Client[] = [];
  allParticipants: ProcessListingV3Participant[] = [];
  selectedParticipantsIds: any = [];
  selectedClientsIds: any = [];

  @Input() title = 'BANNER.LINK.PROJECT_ROOMS_TITLE';
  @Input() subTitle = null;
  @Input() subTitlePrefix = null;
  @Input() enableOrganizationFilter = true;
  @Input() enableClientFilter = true;

  searchForm: UntypedFormGroup;
  workflowsSelectorTypes = [];
  statusSelectorTypes = [];

  private searchTerm$: BehaviorSubject<string> = new BehaviorSubject(null);
  public searchTerm: string = '';
  protected currentSearchTerm = '';

  protected _clientId = null;

  org$;
  organizationId: string;
  routes: ITabNavRoute[];
  activeLink: string;
  protected _processes: ProcessListingItemV3[] = [];
  public data$ = new BehaviorSubject<ProcessListingItemV3[]>([]);
  chartData$ = new BehaviorSubject<ProcessListingV3Statistics>({
    type: 'workflow_engine_process_statistics',
    id: 'default',
    open: 0,
    closed: 0,
    draft: 0,
    all: 0
  });

  selectedWorkflowTypes: any[] = [];
  selectedStatusSelectorTypes: any[] = [FiveFStatusesMap.InProgress];
  workflows = ['project', 'quickshare_v2', 'collector', 'project_room', 'cac', 'cav', 'third_party', 'signature'];
  workflowTranslationMap: { [slug: string]: string };

  clients$ = new BehaviorSubject<Client[]>([]);
  selectedClients: Client[] = [];

  participants$ = new BehaviorSubject<ProcessListingV3Participant[]>([]);
  selectedParticipants: ProcessListingV3Participant[] = [];

  organizations$: Observable<Organization[]>;
  selectedOrganizations: Organization[] = [];

  // Pagination setup
  public loading$ = new BehaviorSubject<boolean>(false);
  public page: number = 1;
  public pages: number = 1;

  public displayedColumns: IProcessListingColumns[] = ['icon', 'title', 'favorite', 'notifications', 'client', 'ident', 'creator', 'status', 'updated-at', 'actions']; // 'created-at'

  @ViewChild('cardHeaderContext', {static: true}) cardHeaderContext: TemplateRef<any>;
  @ViewChild('filtersContainerComponent', {static: true}) filtersContainerComponent: FiltersContainerComponent;

  cardHeaderPortal: Portal<any>;

  featureSet$: Observable<Feature>;

  disableClientFilter = false;
  showReset = false;

  /**
   * Request processes with overridden notification settings.
   * @protected
   */
  protected withOverriddenNotificationSettingsOnly = false;

  @Input() set client(client) {
    if (client) {
      this._clientId = client.id;
    }
  }

  @Input() set parent(parentProcess: IResource) {
    if (parentProcess && parentProcess.id) {
      this._parentId = String(parentProcess.id);
    }
  }

  constructor(protected _store: Store<AppState>,
              protected _processV3Svc: ProcessListingV3Service,
              protected _processSvc: ProcessService,
              protected _clientSvc: ClientService,
              protected _router: Router,
              protected _route: ActivatedRoute,
              protected _viewContainerRef: ViewContainerRef,
              protected _dialog: MatDialog,
              protected _notifyService: NotificationService,
              protected _cdr: ChangeDetectorRef,
              protected _ngZone: NgZone,
              protected _fb: UntypedFormBuilder) {
    this._initWorkflowTranslationMap();
  }

  ngOnInit() {
    this._route.params
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe((params: ParamMap) => {
        const id = params['orgIdOfAdmin'];
        if (Net.validUUID(id)) {
          this.routes = [{
            title: 'PROCESS_MANAGEMENT.OVERVIEW',
            route: `/process_management/admin/${id}/overview`
          }, {
            title: 'PROCESS_MANAGEMENT.ALL_PROJECT_ROOMS',
            route: `/process_management/admin/${id}/project_rooms/all`
          }, {
            title: 'PROJECT_ROOM.ALL_MEMBERS',
            route: `/process_management/admin/${id}/participations/all`
          }];
          this.activeLink = this.routes[1].title;
        }
      });
    this.organizations$ = this._store.select(OrganizationSelectors.getAllOrganizations);
    this.org$ = this._store.select(OrganizationSelectors.getSelected).pipe(filter(org => !!org), distinctUntilKeyChanged('id'));

    // get task count if process not null (project rooms Page)
    if (this._parentId) {
      this._store.dispatch(new ProjectStatisticsActions.LoadAll(this._parentId));
    }
    // this._store.select(ClientSelectors.getClientsOfSelectedOrg)
    //   .pipe(takeUntil(this.onDestroy))
    //   .subscribe(clients => {
    //     this.clients$.next([]);
    //   });


    WORKFLOWS_TYPES.map(type => {
      const iconConfig = Process.iconForType(type);
      this.workflowsSelectorTypes.push({
        id: type,
        title: WORKFLOWS_TYPES_TITLES[type],
        icon: iconConfig.icon,
        isSVGIcon: iconConfig.isSvgIcon
      })
    });

    this.statusSelectorTypes = [
      FiveFStatusesMap.Draft,
      FiveFStatusesMap.InProgress,
      FiveFStatusesMap.Closed
    ];

    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);


    this.createSearchForm();

    this._ngZone.runOutsideAngular(_ => {
      setTimeout(__ => this._store.dispatch(new FavoriteActions.LoadAll), 2000);
    });

    this.org$.pipe(take(1), takeUntil(this.onDestroy)).subscribe(org => {
      this.organizationId = org.id;
      this._ngZone.runOutsideAngular(_ => {
        setTimeout(__ => this._fetchProcessClients());
      });
    });

    let initLoadForRequestDuplication = false;
    this._route.queryParamMap.pipe(takeUntil(this.onDestroy), map(params => ({params}))).subscribe(({params}) => {
      if(!initLoadForRequestDuplication) {
        initLoadForRequestDuplication = true;
        return;
      }
      this.currentSearchTerm = '';
      this.selectedClients = [];
      this.selectedOrganizations = [];
      this.selectedParticipants = [];
      this.selectedWorkflowTypes = [];
      this.currentSearchTerm = '';
      if (params) {
        if (params.get('q')) {
          this.currentSearchTerm = params.get('q');
        }
        if (params.get('clients')) {
          const clients = params.get('clients').split(',');
          if (clients) {
            this.selectedClientsIds = [];
            this.selectedClients = [];
            for (let i = 0; i < clients.length; i++) {
              if (this.allClients && this.allClients.length > 0) {
                const selectedClient = this.allClients.filter(a => a.id === clients[i])
                if (selectedClient && selectedClient.length > 0) {
                  this.selectedClients.push(selectedClient[0]);
                }
              } else {
                this.selectedClientsIds.push(clients[i]);
              }
            }
          }
        }
        if (params.get('uids')) {
          const participants = params.get('uids').split(',');
          if (participants) {
            this.selectedParticipants = [];
            this.selectedParticipantsIds = [];
            for (let i = 0; i < participants.length; i++) {
              if (this.allParticipants && this.allParticipants.length > 0) {
                const selectedParticipant = this.allParticipants.filter(a => a.email === participants[i]);
                if (selectedParticipant && selectedParticipant.length > 0) {
                  this.selectedParticipants.push(selectedParticipant[0]);
                }
              } else {
                this.selectedParticipantsIds.push(participants[i]);
              }
            }
          }
        }
        if (params.get('oids')) {
          const allorganizations = params.get('oids').split(',');
          if (allorganizations) {
            this.selectedOrganizations = [];
            for (let i = 0; i < allorganizations.length; i++) {
              let selectedOrganization;
              this.organizations$.pipe(first()).subscribe(organizations => {
                selectedOrganization = organizations.filter(a => a.id === allorganizations[i]);
                if (selectedOrganization && selectedOrganization.length > 0) {
                  this.selectedOrganizations.push(selectedOrganization[0]);
                }
              })
            }
          }
        }
        if (params.get('workflows')) {
          const allworkflow_types = params.get('workflows').split(',');
          if (allworkflow_types) {
            this.selectedWorkflowTypes = [];
            for (let i = 0; i < allworkflow_types.length; i++) {
              const selectedWorkflow = this.workflowsSelectorTypes.filter(a => a.id === allworkflow_types[i])
              if (selectedWorkflow && selectedWorkflow.length > 0) {
                this.selectedWorkflowTypes.push(selectedWorkflow[0]);
              }
            }
          }
        }
        if (params.get('status')) {
          const statuses = params.get('status').split(',');
          if (statuses) {
            this.selectedStatusSelectorTypes = [];
            for (let i = 0; i < statuses.length; i++) {
              const selectedStatus = this.statusSelectorTypes.filter(a => FiveFStatusToApiMap[a.id] === statuses[i])
              if (selectedStatus && selectedStatus.length > 0) {
                this.selectedStatusSelectorTypes.push(selectedStatus[0]);
              }
            }
          }
        }
      }
      this._loadPage(this.page);
    });

    this.searchTerm$
      .pipe(debounceTime(500), takeUntil(this.onDestroy))
      .subscribe(term => {
        this.currentSearchTerm = term;
        this.loadPage(true);
      });
  }

  createSearchForm() {
    this.searchForm = this._fb.group({
      searchTerm: [null]
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.loading$.complete();
    this.data$.complete();
    this.participants$.complete();
    this.clients$.complete();
    this.searchTerm$.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this.cardHeaderPortal = this._createPortal(this.cardHeaderPortal, this.cardHeaderContext);
    });
  }

  resetSearch() {
    this.currentSearchTerm = '';
    this.loadPage(true);
  }

  public loadMore(event): void {
    if (this.page >= this.pages || (event && !event.visible)) {
      return;
    }
    this.page += 1;
    this.loadPage(false);
  }

  protected loadPage(reset = false) {
    if (reset) {
      this.page = 1;
    }
    const params = this._router.parseUrl(this.getQuery());
    if (this.currentSearchTerm) {
      params.queryParams.q = this.currentSearchTerm;
    }
    if (params && params.queryParams) {
      params.queryParams.reload = Math.floor(Math.random() * 1000) + 1;
    }
    this._router.navigate([], {relativeTo: this._route, queryParams: params.queryParams});
  }

  protected _loadPage(page) {
    if (page === 1) {
      this._processes = [];
      this.page = 1;
    }

    this.loading$.next(true);
    const params: IProcessV3Query = {};
    this._selectedWorkflowTypes(params);
    this._selectedClients(params);
    this._selectedOrganizations(params);
    this._selectedStatus(params);
    this._selectedParticipants(params);

    if (this.currentSearchTerm) {
      params.search = this.currentSearchTerm;
    }

    if (this._parentId) {
      params.parent_id = this._parentId;
    }

    if (this._clientId) {
      params.client = this._clientId;
    }

    // Request processes with overridden notification settings.
    // This special feature is used inside the overwritten notification container.
    if (this.withOverriddenNotificationSettingsOnly) {
      params.ovns = true;
    }

    const selectedFilterCount = this.selectedWorkflowTypes.length + this.selectedClients.length + this.selectedStatusSelectorTypes.length + this.selectedOrganizations.length + this.selectedParticipants.length;
    this.showReset = selectedFilterCount > 0 && !(selectedFilterCount === 1 && this.selectedStatusSelectorTypes.length === 1 && this.selectedStatusSelectorTypes[0] === FiveFStatusesMap.InProgress);
    if (params.search) {
      params.search = encodeURIComponent(params.search);
    }
    this._processV3Svc.getAll(page, params)
      .pipe(first())
      .subscribe(processes => {
        if (this.page === 1 && processes.length === 0) {
          this.pages = 0;
        }

        if (this.page === 1) {
          this._processes = [...processes];
        } else {
          this._processes = [...this._processes, ...processes];
        }
        this.data$.next(this._processes);

        // Pagination counter
        if (processes && processes[0]) {
          this.pages = processes[0].total;
        }
        // Disable auto load loading circle and enable viewport detection
        this.loading$.next(false);
      }, catchError(err => {
        console.error(err);
        return of(err);
      }));
  }

  getQuery() {
    const params: IProcessV3Query = {};
    this._selectedWorkflowTypes(params);
    this._selectedClients(params);
    this._selectedOrganizations(params);
    this._selectedStatus(params);
    this._selectedParticipants(params);

    // if (this.currentSearchTerm) {
    //   params.search = this.currentSearchTerm;
    // }

    if (this._parentId) {
      params.parent_id = this._parentId;
    }

    if (this._clientId) {
      params.client = this._clientId;
    }

    let queryParams = `?page=${this.page}`;
    const _queryParams = [];
    if (params && params['workflows']) {
      _queryParams.push(`workflows=${params['workflows'].join(',')}`);
    }

    if (params && params['clients']) {
      _queryParams.push(`clients=${params['clients'].join(',')}`);
    }

    if (params && params['client']) {
      _queryParams.push(`client=${params['client']}`);
    }

    if (params && params['organizations']) {
      _queryParams.push(`oids=${params['organizations'].join(',')}`);
    }

    if (params && params['participants']) {
      _queryParams.push(`uids=${params['participants'].join(',')}`);
    }

    if (params && params['status']) {
      _queryParams.push(`status=${params['status'].join(',')}`);
    }

    // if (params && params['search']) {
    //   _queryParams.push(`q=${params['search']}`);
    // }

    if (params && params['parent_id']) {
      _queryParams.push(`parent_id=${params['parent_id']}`);
    }

    queryParams = _queryParams.length > 0 ? `${queryParams}&${_queryParams.join('&')}` : queryParams;
    return queryParams;
  }

  public navigateTo(process: IProcessListingItem): void {
    if (!process) return;
    this._notifyService.info('PROJECT_ROOM.LOADING_PROJECT_ROOM')
    this._store.dispatch(new ProcessActions.RunCommand('' + process.id, 'index', 'on_click'));
  }

  public selectWorkflow(selected: string[]) {
    this.clearFilterSearch();
    let _selected = selected;
    if (selected && selected.includes('third_party')) {
      _selected = [..._selected, ...Process.thirdPartyTypes()];
    }
    this.selectedWorkflowTypes = _selected;
    this.loadPage(true);
  }

  public selectStatus(selected: string[]) {
    this.clearFilterSearch();
    this.selectedStatusSelectorTypes = selected;
    this.loadPage(true);
  }

  public selectFilterByStats($event) {
    if ($event && $event.name) {
      switch ($event.name) {
        case 'Entwurf':
        case 'Draft':
          this.selectedStatusSelectorTypes = [FiveFStatusesMap.Draft];
          break;
        case 'In Bearbeitung':
        case 'In progress':
          this.selectedStatusSelectorTypes = [FiveFStatusesMap.InProgress];
          break;
        case 'Closed':
        case 'Geschlossen':
          this.selectedStatusSelectorTypes = [FiveFStatusesMap.Closed];
          break;
        default:
          console.error('Unknown Filter key: ', $event.name);
      }
      this._cdr.detectChanges();
      this.loadPage(true);
    }
  }

  public selectClient(clients) {
    this.clearFilterSearch();
    this.selectedClients = clients;
    this.selectedClientsIds = clients.map(a => a.id);
    this.loadPage(true);
  }

  public selectParticipant(participants) {
    this.clearFilterSearch();
    this.selectedParticipants = participants;
    this.selectedParticipantsIds = participants.map(a => encodeURIComponent(a.email));
    this.loadPage(true);
  }

  public selectOrganization(organizations) {
    this.clearFilterSearch();
    this.selectedOrganizations = organizations;
    this.loadPage(true);
  }

  protected _createPortal(ref: Portal<any>, context: TemplateRef<any>): Portal<any> {
    if (ref) return ref;
    return new TemplatePortal(context, this._viewContainerRef);
  }

  protected _selectedWorkflowTypes(params) {
    if (this.selectedWorkflowTypes && this.selectedWorkflowTypes.length) {
      if (!params.workflows) params.workflows = [];
      params.workflows = this.selectedWorkflowTypes.map(type => type.id);
    }
  }

  protected _selectedParticipants(params: IProcessV3Query) {
    if (this.selectedParticipants && this.selectedParticipants.length) {
      if (!params.participants) params.participants = [];
      params.participants = this.selectedParticipants.map(type => encodeURIComponent(type.email));
    } else if (this.selectedParticipantsIds && this.selectedParticipantsIds.length > 0) {
      if (!params.participants) params.participants = [];
      params.participants = this.selectedParticipantsIds;
    }
  }

  protected _selectedClients(params: IProcessV3Query) {
    if (this.selectedClients && this.selectedClients.length) {
      if (!params.clients) params.clients = [];
      params.clients = this.selectedClients.map(c => c.id);
    } else if (this.selectedClientsIds && this.selectedClientsIds.length > 0) {
      if (!params.clients) params.clients = [];
      params.clients = this.selectedClientsIds;
    }
  }

  protected _selectedOrganizations(params: IProcessV3Query) {
    if (this.selectedOrganizations && this.selectedOrganizations.length) {
      if (!params.organizations) params.organizations = [];
      params.organizations = this.selectedOrganizations.map(o => o.id);
    }
  }

  protected _selectedStatus(params: IProcessV3Query) {
    if (this.selectedStatusSelectorTypes && this.selectedStatusSelectorTypes.length) {
      if (!params.status) params.status = [];
      params.status = this.selectedStatusSelectorTypes.map(c => FiveFStatusToApiMap[c.id]);
    }
  }

  protected _initWorkflowTranslationMap() {
    this.workflowTranslationMap = Process.getWorkflowTranslationMap();
  }

  onClearAllFilters() {
    this.clearFilterSearch();
    this.selectedClients = [];
    this.selectedWorkflowTypes = [];
    this.selectedStatusSelectorTypes = [FiveFStatusesMap.InProgress];
    this.selectedParticipants = [];
    this.selectedClientsIds = [];
    this.selectedParticipantsIds = [];
    this.selectedOrganizations = [];
    this.resetSearch();
    this.currentSearchTerm = '';
    this.loadPage(true);
  }

  showAll() {
    this.clearFilterSearch();

    this.selectedClients = [];
    this.selectedWorkflowTypes = [];
    this.selectedStatusSelectorTypes = [
      FiveFStatusesMap.Draft,
      FiveFStatusesMap.InProgress,
      FiveFStatusesMap.Closed
    ];
    this.resetSearch();
    this.currentSearchTerm = '';
    this.loadPage(true);
  }

  onSaveFilters() {
  }

  clearFilterSearch() {
    this.searchForm.reset();
  }

  onCloseFiltersContainer() {
    this.filtersContainerComponent.containerDropdownMenu.toggleDropdown();
  }

  removeDraft(process: ProcessListingItemV3) {
    const item = process;
    this._processSvc.remove(item.id)
      .pipe(first())
      .subscribe((_process: Process) => {
        this.data$.next(this.data$.value.filter(p => p.id !== item.id));
      });
  }

  protected _fetchProcessClients() {
    if (this._clientId) {
      return;
    }
    const parentId = this._parentId;
    this._clientSvc.getAllForProcesses(parentId).pipe(first()).subscribe(clients => {
      this.allClients = clients;
      this.clients$.next(clients);
      if (clients && this.selectedClientsIds && this.selectedClientsIds.length > 0) {
        this.selectedClients = clients.filter(a => this.selectedClientsIds.includes(a.id));
      }
    }, err => {
      console.error(err);
    });
  }

  /**
   * Search processes for term.
   * @param term
   */
  public applySearch(term: string): void {
    this.searchTerm$.next(term);
  }
}
