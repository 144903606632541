<ul *ngIf="profiles" [class]="containerClass">
  <li *ngFor="let profile of profiles | slice:0:maxToShow" [class]="itemsClass+' avatar--group--item'">
    <dvtx-avatar
      [size]="avatarSizeString"
      [profile]="getProfile(profile.email, profile)"
      [matTooltip]="profile?.name || profile.email">
    </dvtx-avatar>
  </li>
  <li [class]="itemsClass" *ngIf="maxToShow < profiles.length && showMore" >
    <span
      #ShowTooltipButton
      *ngIf="!scrollableDynamicTooltip"
      class="avatar-icon mat-fab mat-elevation-z"
      [style.color]="profiles[0].fgColor"
      [style.background-color]="profiles[0].bgColor"
      [style.border-color]="profiles[0].bgColor"
      [style.width.px]="avatarSizeNumber"
      [style.height.px]="avatarSizeNumber"
      [style.lineHeight.px]="avatarSizeNumber"
      (click)="showAllListTooltip($event)">
      +{{ profiles.length - maxToShow }}
    </span>

    <span
    *ngIf="scrollableDynamicTooltip"
    #ShowTooltipButton
    class="avatar-icon mat-fab mat-elevation-z"
    [style.color]="profiles[0].fgColor"
    [style.background-color]="profiles[0].bgColor"
    [style.border-color]="profiles[0].bgColor"
    [style.width.px]="avatarSizeNumber"
    [style.height.px]="avatarSizeNumber"
    [style.lineHeight.px]="avatarSizeNumber"
    (click)="showAllListTooltip($event)"
    [matMenuTriggerFor]="displayMenu">
    +{{ profiles.length - maxToShow }}
  </span>
     <mat-menu #displayMenu="matMenu">
      <div *ngIf="scrollableDynamicTooltip && contentTooltipAvatar" style="max-height: 200px;overflow: auto;">
        <mat-card>
          <mat-card-content class="p-0">
            <div mat-dialog-content class="p-0">
              <div class="" [innerHTML]="contentTooltipAvatar">
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
     </mat-menu>
  </li>
</ul>
