<dvtx-tooltip [tooltipText]="'Reaktionen'"
              [position]="'top left'"
              cdk-overlay-origin
              (click)="showDropdown = !showDropdown"
              (mouseenter)="openShowDropdown(true)"
              (mouseleave)="closeShowDropdown(false)"
              #dropdown="cdkOverlayOrigin">
  <button color="gray" mat-icon-button (click)="react()">
    <span class="dvtx-comment-reaction--smiley-bg"></span>
    <mat-icon class="dvtx-comment-reaction">{{icon}}</mat-icon>
    <span class="text" *ngIf="reaction?.performers?.length">
          ({{reaction.performers.length}})
        </span>
  </button>
</dvtx-tooltip>

<ng-template cdk-connected-overlay
             [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-auto-size-backdrop'"
             [cdkConnectedOverlayOrigin]="dropdown"
             [cdkConnectedOverlayHasBackdrop]="true"
             [cdkConnectedOverlayOpen]="showDropdown || keepOpen"
             (backdropClick)="showDropdown = false">
  <div style="background-color: white;" class="mat-elevation-z2 px-3"
       (mouseenter)="toggleKeepOpen(true)"
       (mouseleave)="toggleKeepOpen(false)">
    <div class="d-flex">
      <div><button color="gray" mat-icon-button (click)="react('like')"><mat-icon class="dvtx-comment-reaction">thumb_up</mat-icon></button></div>
      <div><button color="gray" mat-icon-button (click)="react('laugh')"><mat-icon class="dvtx-comment-reaction">insert_emoticon</mat-icon></button></div>
      <div><button color="gray" mat-icon-button (click)="react('surprised')"><mat-icon class="dvtx-comment-reaction">sentiment_neutral</mat-icon></button></div>
      <div><button color="gray" mat-icon-button (click)="react('sad')"><mat-icon class="dvtx-comment-reaction">sentiment_dissatisfied</mat-icon></button></div>
      <div><button color="gray" mat-icon-button (click)="react('angry')"><mat-icon class="dvtx-comment-reaction">mood_bad</mat-icon></button></div>
      <div><button color="gray" mat-icon-button (click)="react('heart')"><mat-icon class="dvtx-comment-reaction">favorite</mat-icon></button></div>
    </div>
    <ng-container *ngIf="reaction?.performers">
      <hr>
      <ul style="margin: 0;">
        <li class="my-1" *ngFor="let performer of reaction.performers">
          <dvtx-avatar mat-list-icon [size]="'xs'" [profile]="avatarService.getProfile(performer)" [showTitle]='showAvatarTitle'></dvtx-avatar>
        </li>
      </ul>
    </ng-container>
  </div>
</ng-template>
