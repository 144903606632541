<dvtx-user-profile-settings-header [routes]="routes" [activeLink]="activeLink" [title]="'USER_NAVIGATON.PROFILE'"
  [subtitle]="'USER.USER_DATA'">
</dvtx-user-profile-settings-header>

<div class="five-f-settings-container">
  <div class="row w-100 mx-0 mb-2" *ngIf="myAvatarObject">
    <div
      class="col-12 col-md-10 col-xl-8 col-xxl-7 col-xxxl-6 d-flex align-items-center user--profile--avatar--input">
      <dvtx-avatar [profile]="myAvatarObject" [size]="'xl'" [showTick]="false"></dvtx-avatar>
      <span class="container--avatar--actions--button">
        <button mat-icon-button color="gray" type="button" (click)="profileImageChangeRequest()" class="add-button"
          [disableRipple]="true" [matTooltip]="'GENERAL.EDIT_ACTION' | translate">
          <mat-icon>photo_camera</mat-icon>
        </button>
        <button *ngIf="myAvatarObject.image || avatar" mat-icon-button color="gray" type="button"
          (click)="profileImageRemove()" class="add-button" [disableRipple]="true"
          [matTooltip]="'GENERAL.DELETE_ACTION' | translate">
          <mat-icon>delete</mat-icon>
        </button>
      </span>
      <input type="file" (change)="profileImageChangeEvent($event)" class="d-none" id="uploadProfileButton"
        accept="image/*" />
    </div>
  </div>

  <div class="row w-100 mx-0 mb-2 ">
    <div class="col-12 col-md-10 col-xl-8 col-xxl-7 col-xxxl-6">
      <div class="d-flex align-items-center mt-1 mb-2">
        <div>
          <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200 mr-2">info</mat-icon>
        </div>
        <p [innerHTML]="'UPLOAD.PLACEHOLDER_IMAGE_UPLOAD_ERROR' | translate" class="mb-0"></p>
      </div> 
    </div>
  </div>

    <div style="position: absolute;top: 400px;" class="col-12 col-md-10 col-xl-8 col-xxl-7 col-xxxl-6 d-flex justify-content-end align-items-center">
      <button *ngIf="!edit" mat-raised-button color="primary" (click)="edit = true; _cdr.detectChanges()"
        class="mx-2">{{'GENERAL.EDIT_ACTION' | translate}} </button>
      <button *ngIf="edit" mat-raised-button (click)="edit = false;resetChanges()" class="mx-2">{{'GENERAL.CANCEL_ACTION' |
        translate}}</button>
      <button *ngIf="edit" mat-raised-button color="primary" [disabled]="!naturalPerson?.isValid" (click)="save()"
        class="mx-2">{{'GENERAL.SAVE_ACTION' | translate}} </button>
    </div>

  <div class="row w-100 mx-0 mb-1">
    <div class="col-12 col-md-10 col-xl-8 col-xxl-7 col-xxxl-6 d-flex align-items-center">
      <dvtx-user-profile-form *ngIf="naturalPerson" [(ngModel)]="naturalPerson" [isDisabled]="!edit" class="w-100">
      </dvtx-user-profile-form>
    </div>
  </div>

</div>



<ng-template cdkPortal #context>
  <image-cropper [imageChangedEvent]="profileImageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1"
    [resizeToWidth]="128" format="png" (imageCropped)="profileImageCropEvent($event)"
    (imageLoaded)="profileImageLoaded()" (loadImageFailed)="profileImageLoadedFailed()" class="p-0">
  </image-cropper>
</ng-template>

<ng-template cdkPortal #buttonsToolbar>
  <button mat-button color="warn" mat-dialog-close>{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
  <button mat-raised-button color="primary" (click)="onSubmitCroppingClick()">{{ 'GENERAL.SAVE_ACTION' | translate
    }}</button>
</ng-template>