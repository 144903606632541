import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ProcessListingItemV3 } from 'app/+store/process/process';
import { AvatarService } from 'app/shared/modules/user-account/components/avatar/avatar.service';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { ProcessListingV3Service } from 'app/+store/process/process-listing-v3.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'app/shared/modules/notification/services/notification.service';
import { ProcessActions } from 'app/+store';
import { IProcessV3Query } from 'app/+store/process/process.interface';

@Component({
  selector: 'dvtx-search-navbar',
  templateUrl: './search-navbar.component.html',
  styleUrls: ['./search-navbar.component.scss']
})
export class SearchNavbarComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();
  public displayedColumns = ['project', 'type', 'view'];
  public dataSource: MatTableDataSource<ProcessListingItemV3> = new MatTableDataSource<ProcessListingItemV3>([]);
  private _processes: ProcessListingItemV3[] = [];
  public data$ = new BehaviorSubject<ProcessListingItemV3[]>([]);
  @Output() closeDropdown: EventEmitter<any> = new EventEmitter<any>();
  // Pagination setup
  public loading$ = new BehaviorSubject<boolean>(false);
  public page: number = 1;
  public pages: number = 1;
  private currentSearchTerm = '';
  @Input() set search(search: string) {
    this.currentSearchTerm = search;
    this._loadPage(1);
  }

  constructor(private _store: Store<AppState>,
    public avatarService: AvatarService,
    private _processV3Svc: ProcessListingV3Service,
    private _router: Router,
    protected _translateSvc: TranslateService,
    private _notifyService: NotificationService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.loading$.complete();
    this.data$.complete();
  }

  public loadMore(event): void {
    if (this.page >= this.pages || (event && !event.visible)) {
      return;
    }
    this.page += 1;
    this._loadPage(this.page);
  }

  private _loadPage(page) {
    if (page === 1) {
      this._processes = [];
      this.page = 1;
    }
    this.loading$.next(true);
    const params: IProcessV3Query = {};
    if (this.currentSearchTerm) {
      params.search = this.currentSearchTerm;
    }
    if (params.search) {
      params.search = encodeURIComponent(params.search);
    }
    this._processV3Svc.getAllMinifiedRes(page, params)
      .pipe(first())
      .subscribe(processes => {
        if (this.page === 1 && processes.length === 0) {
          this.pages = 0;
        }

        if (this.page === 1) {
          this._processes = [...processes];
        } else {
          this._processes = [...this._processes, ...processes];
        }
        this.data$.next(this._processes);

        this.dataSource.data = this._processes;
        // Pagination counter
        if (processes && processes[0]) {
          this.pages = processes[0].total;
        }
        // Disable auto load loading circle and enable viewport detection
        this.loading$.next(false);
      }, catchError(err => {
        console.error(err);
        return of(err);
      }));
  }

  getQuery() {
    let queryParams = `?page=${this.page}`;
    const _queryParams = [];
    queryParams = _queryParams.length > 0 ? `${queryParams}&${_queryParams.join('&')}` : queryParams;
    return queryParams;
  }

  public navigateToProcess(process) {
    if (!process) return;
    this._notifyService.info('PROJECT_ROOM.LOADING_PROJECT_ROOM')
    this._store.dispatch(new ProcessActions.RunCommand(process.id, 'index', 'on_click'));
    this.closeDropdown.emit();
  }

  navigateToSearchPage() {
    this._router.navigate([`/workflows/my-workflows`]);
    this.closeDropdown.emit();
  }
}
