import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {FlatProcessBuilder, ProcessBuilder, ProcessViewSettingsBuilder} from './process.builder';
import {FlatProcess, Process, ProcessSettingsViewType, ProcessViewSettings} from './process';
import {Router} from '@angular/router';
import {Favorite} from '../favorite/favorite';
import {FavoriteBuilder} from '../favorite/favorite.builder';
import {IProcessQuery} from '../process-event/process-event.interface';
import {ProcessPolicyBuilder} from '../iam/process-policy.builder';
import * as api from 'app/+store/iam/process-policy.interface';
import {first, switchMap} from 'rxjs/operators';
import * as iam from '../iam/permission-policy-manager';
import {IProcessPropertyRequest} from './process.interface';
import {AngularTokenService} from 'angular-token';
import {of} from 'rxjs/internal/observable/of';
import * as iamInterface from '../iam/process-policy.interface';
import {FileApiResourceService} from '../process-artifact/file-api-resource.service';
import {EnvService} from '../../shared/modules/api-resource/services/env.service';

@Injectable()
export class ProcessService {
  readonly BASE_PATH = 'api/v1/workflow_engine/processes';
  readonly BASE_PATH_V3 = 'api/v3/workflow_engine/processes';

  builder: ProcessBuilder;
  FavoriteBuilder = new FavoriteBuilder();

  constructor(private _http: ApiResourceService,
              private _fhttp: FileApiResourceService,
              private _router: Router,
              private _tokenSvc: AngularTokenService,
              private _env: EnvService) {
    this.builder = new ProcessBuilder(_router);
  }

  /**
   * Returns all processes accessible by user.
   * @param ids Restrict processes fetched to those included in +ids+ (comma separated list of process UUIDs)
   * @param query
   * @param dense Parameter enabled the new netflix INDEX serialization. Attention: No all attributes are delivered only base parameters.
   */
  getAll(pids: string[] = null, query: IProcessQuery = null): Observable<Process[]> {
    // if (query && query.debug) {
    //   console.error('DEBUG: ', query.debug);
    //   console.error('DEBUG: ids', ids);
    //   console.error('DEBUG: query', query);
    // }
    const _queryParams = [];
    let ids = pids;
    if (!ids && query) {
      ids = query.processIds;
    }
    let payload;
    if (ids) {
      payload = {
        data: {
          attributes: {
            selected_ids: ids
          }
        }
      }
    }
    ;
    if (query && query['workflow']) {
      _queryParams.push(`workflow=${query['workflow']}`);
    }
    if (query && query['dmsFolderId']) {
      _queryParams.push(`dms_folder_id=${query['dmsFolderId']}`);
    }
    if (query) {
      _queryParams.push(`dense=true`);
    }
    const queryParams = _queryParams.length > 0 ? `?${_queryParams.join('&')}` : '';
    if (ids) {
      // if (query && query.debug) {
      //   console.error('DEBUG: POST ALL');
      // }
      return <Observable<Process[]>>this._http.postAll<ProcessBuilder, Process>(this.builder, `${this.BASE_PATH}/selection${queryParams}`, payload);
    } else {
      // if (query && query.debug) {
      //   console.error('DEBUG: GET');
      // }
      return <Observable<Process[]>>this._http.get<ProcessBuilder, Process>(this.builder, `${this.BASE_PATH}${queryParams}`);
    }
  }

  getAllFromParent(parentId, dense = false): Observable<Process[]> {
    return <Observable<Process[]>>this._http.get<ProcessBuilder, Process>(this.builder, `${this.BASE_PATH}?parent_id=${parentId}&dense=${dense}`);
  }

  getAllFromClient(clientId): Observable<Process[]> {
    return <Observable<Process[]>>this._http.get<ProcessBuilder, Process>(this.builder, `${this.BASE_PATH}?client_id=${clientId}`);
  }

  getOne(id: string, dense = false): Observable<Process> {
    const query = dense ? '?dense=true' : '';
    return <Observable<Process>>this._http.get<ProcessBuilder, Process>(this.builder, `${this.BASE_PATH}/${id}${query}`);
  }

  track(id: string): Observable<Process> {
    return <Observable<Process>>this._http.post<ProcessBuilder, Process>(this.builder, `${this.BASE_PATH}/${id}/track`, {});
  }

  move(id: string, targetId: string): Observable<Process> {
    const payload = {data: {attributes: {parent_id: targetId}}};
    return <Observable<Process>>this._http.post<ProcessBuilder, Process>(this.builder, `${this.BASE_PATH}/${id}/move`, payload);
  }

  remove(id: string): Observable<Process> {
    return <Observable<Process>>this._http.del<ProcessBuilder, Process>(this.builder, `${this.BASE_PATH}/${id}`);
  }

  favorite(item: Process, id: string): Observable<Favorite> {
    this.FavoriteBuilder = new FavoriteBuilder();
    const payload = this.builder.toRequest(item);
    if (id) {
      return <Observable<Favorite>>this._http.post<FavoriteBuilder, Favorite>(this.FavoriteBuilder, `${this.BASE_PATH}/${id}/favorite`, payload);
    } else {
      return <Observable<Favorite>>this._http.post<FavoriteBuilder, Favorite>(this.FavoriteBuilder, `${this.BASE_PATH}/${item.id}/favorite`, payload);
    }
  }

  activateForProjectRoom(id) {
    const payload = {data: {attributes: {activate_big_files: true}}};
    return <Observable<Process>>this._http.post<ProcessBuilder, Process>(this.builder, `${this.BASE_PATH}/${id}/process_properties/activate_big_files`, payload);
  }

  deactivateForProjectRoom(id) {
    const payload = {data: {attributes: {activate_big_files: false}}};
    return <Observable<Process>>this._http.post<ProcessBuilder, Process>(this.builder, `${this.BASE_PATH}/${id}/process_properties/deactivate_big_files`, payload);
  }

  loadEnv(id: string, action: string): Observable<Process> {
    const payload = {data: {attributes: {action: action}}};
    return <Observable<Process>>this._http.post<ProcessBuilder, Process>(this.builder, `${this.BASE_PATH}/${id}/process_commands`, payload);
  }

  /**
   * @deprecated This is API will be removed in favor of v3 single property
   *             update API below: setProcessProperty.
   *
   * Found occurances:
   * - CollectorSettingsTabComponent
   * - SenderDashboardComponent
   * - TimelineBannerComponent
   *   - colorPicked
   *   - selectColor
   - TimelineSettingsDialogComponent: This component and dependencies will be totally removed in favor of
   *                                    the new stand alone settings view.
   * @param id: string
   * @param data: IProcessPropertyRequest
   */
  setProperties(id: string, data: IProcessPropertyRequest): Observable<Process> {
    const payload = {data: {attributes: data}};
    return <Observable<Process>>this._http.post<ProcessBuilder, Process>(this.builder, `${this.BASE_PATH}/${id}/process_properties`, payload);
  }

  setProcessProperty(processId: string, property: string, value, recursive = false): Observable<FlatProcess> {
    const builder = new FlatProcessBuilder();
    const payload = {data: {attributes: {}}};
    // Setting to null is required here otherwise the property is not set correctly, attributes will be empty.
    // Example month select at general settings and reset.
    payload['data']['attributes'][property] = value || null;
    return <Observable<FlatProcess>>this._http.put<FlatProcessBuilder, FlatProcess>(builder, `api/v3/workflow_engine/processes/${processId}/properties/${property}?recursive=${recursive}`, payload);
  }

  loadIamPolicy(email: string, processId: string): void {
    const builder = new ProcessPolicyBuilder();
    const uid = btoa(email);
    this._http.get<ProcessPolicyBuilder, api.Iam.IProcessPolicy>(builder, `api/v3/iam/processes/${processId}/policies/${uid}`)
      .pipe(first())
      .subscribe((iamPolicy: api.Iam.IProcessPolicy) => {
        const context = new iam.Iam.ProcessContext(email, processId);
        const iamProcessManager = <iam.Iam.ProcessPermissionManager>(
          iam.Iam.PermissionManager.getInstance(context)
        );
        iamProcessManager.dispatch(iamPolicy)
      })
  }

  loadMyIamPolicy(processId: string): void {
    const builder = new ProcessPolicyBuilder();
    const email = this._tokenSvc.currentAuthData.uid;
    const uid = btoa(email);
    this._http.get<ProcessPolicyBuilder, api.Iam.IProcessPolicy>(builder, `api/v3/iam/processes/${processId}/policies/${uid}`)
      .pipe(first())
      .subscribe((iamPolicy: api.Iam.IProcessPolicy) => {
        const context = new iam.Iam.ProcessContext(email, processId);
        const iamProcessManager = <iam.Iam.ProcessPermissionManager>(
          iam.Iam.PermissionManager.getInstance(context)
        );
        iamProcessManager.dispatch(iamPolicy)
      })
  }

  myIamProcessPolicy(processId): Observable<api.Iam.IProcessPolicy> {
    if (!processId) {
      return of(iamInterface.Iam.DEFAULT_PROCESS_POLICY)
    }

    const uid = this._tokenSvc.currentAuthData.uid;
    const context = new iam.Iam.ProcessContext(uid, processId);
    const iamProcessManager = <iam.Iam.ProcessPermissionManager>(
      iam.Iam.PermissionManager.getInstance(context)
    );
    return iamProcessManager.policy$;
  }

  loadIamPolicyAndReturn(email: string, processId: string, authModules: api.Iam.IAuthModules[] = []): Observable<api.Iam.IProcessPolicy> {
    const builder = new ProcessPolicyBuilder();
    const uid = btoa(email);
    let params = '';
    if (authModules && authModules.length) {
      const _authModules = authModules.join(',');
      params = `?modules=${_authModules}`;
    }
    return this._http.get<ProcessPolicyBuilder, api.Iam.IProcessPolicy>(builder, `api/v3/iam/processes/${processId}/policies/${uid}${params}`)
      .pipe(
        first(),
        switchMap((iamPolicy: api.Iam.IProcessPolicy) => {
          const context = new iam.Iam.ProcessContext(email, processId);
          const iamProcessManager = <iam.Iam.ProcessPermissionManager>(
            iam.Iam.PermissionManager.getInstance(context)
          );
          return iamProcessManager.load(iamPolicy);
        }));
  }

  loadMyIamPolicyAndReturn(processId: string, authModules: api.Iam.IAuthModules[] = []): Observable<api.Iam.IProcessPolicy> {
    const uid = this._tokenSvc.currentAuthData.uid;
    let params = '';
    if (authModules && authModules.length) {
      const _authModules = authModules.join(',');
      params = `?modules=${_authModules}`;
    }
    return this.loadIamPolicyAndReturn(uid, processId, authModules);
  }

  /**
   * Returns the process' view settings of the according view type (overview, artifacts etc.) of process and user.
   *
   * @param processId
   * @param viewIdentifier: Identifies which view settings of the process will be saved.
   */
  getProcessViewSettings(processId: string, viewIdentifier: ProcessSettingsViewType): Observable<ProcessViewSettings> {
    const builder = new ProcessViewSettingsBuilder(viewIdentifier);
    return <Observable<ProcessViewSettings>>this._http.get<ProcessViewSettingsBuilder, ProcessViewSettings>(builder, `api/v3/workflow_engine/processes/${processId}/view/${viewIdentifier}/device/web`);
  }

  /**
   * Updates the process' view settings of the according view type (overview, artifacts etc.) of process and user.
   * The config depends on the used filters, or other data independent view settings.
   *
   * @param processId
   * @param viewIdentifier: Identifies which view settings of the process will be saved.
   */
  updateProcessViewSettings(processId: string, viewIdentifier: ProcessSettingsViewType, config: any): Observable<ProcessViewSettings> {
    const builder = new ProcessViewSettingsBuilder(viewIdentifier);
    const payload = {
      data: {
        attributes: {
          config: config
        }
      }
    }
    return <Observable<ProcessViewSettings>>this._http.put<ProcessViewSettingsBuilder, ProcessViewSettings>(builder, `api/v3/workflow_engine/processes/${processId}/view/${viewIdentifier}/device/web`, payload);
  }

  /**
   * Returns the participation export as excel.
   *
   * @param processId
   * @param filename
   */
  downloadParticipationExport(processId: string, filename: string) {
    return this._fhttp.getBlob( `${this._env.tusServer()}/${this.BASE_PATH_V3}/${processId}/participation_export`, filename, this._tokenSvc.currentAuthData);
  }
}
