<ng-container *ngIf="(_selectedProfiles | async)?.length as selectedProfilesLength">
  <dvtx-avatars-group [maxToShow]="maxToShow" [size]="'xs'" [profiles]="_selectedProfiles | async" [listStyle]="'inline'" [extraClasses]="' assignees--avatars '" [showMore]="false"></dvtx-avatars-group>
  <ng-template [ngIf]="selectedProfilesLength > maxToShow">
    <span
      cdk-overlay-origin
      class="hidden--assignees--count"
      (click)="showDropdown = !showDropdown"
      #dropdown="cdkOverlayOrigin">
      +{{(_selectedProfiles | async).length - maxToShow}}
    </span>

    <ng-template
      cdk-connected-overlay
      [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
      [cdkConnectedOverlayOrigin]="dropdown"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayOpen]="showDropdown"
      (backdropClick)="showDropdown = false">
      <div class="mat-elevation-z8 p-0 list--container">
        <div class="px-3 py-1">
          <ul class="m-0">
            <li class="mb-1" *ngFor="let person of (_selectedProfiles | async) | slice:maxToShow:selectedProfilesLength">
              <dvtx-avatar mat-list-icon [size]="'xs'" [profile]="avatarService.getProfile(person.email, person)"
                           [showTitle]="true"
                           [showEmail]="true"></dvtx-avatar>
            </li>
          </ul>
        </div>
      </div>
    </ng-template>
  </ng-template>
</ng-container>
